
import { Component, Vue } from 'vue-property-decorator';
import Loader from '@/components/Loader.vue'; // @ is an alias to /src
import eventBus from './services/eventBus';

@Component({
  components: {
    Loader,
  },
})
export default class App extends Vue {
  data():any {
    return {
      loading: false,
      errorModalMsg: null,
      successModalMsg: null
    };
  }

  async mounted() {
    await this.$store.dispatch('loadIdentity');
  }

  created() {
    eventBus.$on('loading', (loading:boolean) => { this.$data.loading = loading; });
    eventBus.$on('makeToast', (variant:string, title:string, content:string) => {   
    this.$bvToast.toast(content, {
          title: title,
          variant: variant,
          solid: true
        })
    });
  }
    
}
