import axios from "axios";

export interface Product {
    id:         number;
    status:     number;
    recipes:    number;
    price:      number;
    position:   number;
    created_at: number;
    updated_at: number;
}
class ProductService {

    getAll():Promise<Product[]> {
        return axios.get(`/v1/local-product/index`);
    }

    getAllPublic():Promise<Product[]> {
        return axios.get(`/v1/local-product/public`);
    }
}

export default new ProductService();
