
import { Component, Watch, Vue } from 'vue-property-decorator';
import RecipeCostService, { IngredientCost, RecipeCost } from '@/services/recipes_cost.service';

import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Steps from '@/components/recipe-detail/Steps.vue';
import Info from '@/components/Info.vue';
import HelpButton from '@/components/HelpButton.vue';
import { GenericResponse } from '@/services/interceptors';

import { Validate, Validations } from 'vuelidate-property-decorators';
import { required, minValue } from 'vuelidate/lib/validators';
import eventBus from '@/services/eventBus';
import Price from '@/components/recipe-cost/Price.vue';
import PriceQuantityFor from '@/components/recipe-cost/PriceQuantityFor.vue';
import PriceUnit from '@/components/recipe-cost/PriceUnit.vue';
import UnitPackagingCost from '@/components/recipe-cost/UnitPackagingCost.vue';
import BatchOverheadCost from '@/components/recipe-cost/BatchOverheadCost.vue';
import YourMarginPercent from '@/components/recipe-cost/YourMarginPercent.vue';
import DistributorMarginPercent from '@/components/recipe-cost/DistributorMarginPercent.vue';
import BatchLaborCost from '@/components/recipe-cost/BatchLaborCost.vue';

@Component({
  components: {
    Header, 
    Footer, 
    Info,
    Sidebar,
    Steps,
    HelpButton,
    'price': Price,
    'price-quantity-for': PriceQuantityFor,
    'price-unit': PriceUnit,
    'unit-packaging-cost': UnitPackagingCost,
    'batch-labor-cost': BatchLaborCost,
    'batch-overhead-cost': BatchOverheadCost,
    'your-margin-percent': YourMarginPercent,
    'distributor-margin-percent': DistributorMarginPercent
  },
})
export default class RecipeCosts extends Vue {

  @Validations()
  validations() {
      return {
          model: {
            unit_packaging_cost: { required, minValue: minValue(0) },
            batch_labor_cost: { required, minValue: minValue(0) },
            batch_overhead_cost: { required, minValue: minValue(0) },
            your_margin_percent: { required, minValue: minValue(0) },
            distributor_margin_percent: { required, minValue: minValue(0) },
            ingredients: {
              $each: {
                  price: { required, minValue: minValue(0) },
                  price_quantity_for: { required, minValue: minValue(0) },
                  shipping: { required, minValue: minValue(0) }
              }
            }
          }
      };
  }
  @Watch('model.ingredients', { deep: true })
  onModelIngredientsChange(newVal: IngredientCost[]):void {
    this.$data.ingredients = newVal;
  }
  
  data():any {
    return {
      model: null,
      ingredients: [],
      fields: [
        { key: 'index', label: '#' },
        { key: 'name', label: 'Nombre' },
        { key: 'batchCost', label: 'Costo/Lote', tdClass: 'text-right', thClass: 'text-right'},
        { key: 'price', label: 'Precio ($)', tdClass: 'text-right', thClass: 'text-right'},
        { key: 'price_quantity_for', label: 'Cantidad', tdClass: 'text-right', thClass: 'text-right'},
        { key: 'price_unit', label: 'Unidad', tdClass: 'text-right', thClass: 'text-right'},
        // { key: 'shipping', label: 'Envio ($)', tdClass: 'text-right', thClass: 'text-right'},
      ],
      unitOptions: [
        // { value: 'cup', 'text': '1 taza' },
        // { value: 'lb', 'text': '1 libra' },
        // { value: 'gallon', 'text': '1 galón' },
        // { value: 'oz', 'text': '1 onza' },
        { value: 'kg', 'text': '1 kg' },
        { value: 'gram', 'text': '1 gramo' },
        { value: 'ml', 'text': '1 ml' },
        { value: 'l', 'text': '1 l' },
        
      ],
      canContinue: false,
    };
  }

  // @Watch('model', { deep: true })
  // onModelChange(newVal: RecipeCost):void {

  //   this!['$v'].$touch()
  //   if (!this!['$v'].$invalid) {

  //     // console.log(newVal);
  //     for(let i = 0; i < newVal.ingredients.length; i++) {
  //       RecipeCostService.updateIngredient(this.$data.model.id, newVal.ingredients[i].id, {
  //         // batchCost: this.getIngredientBatchCost(i),
  //         price: newVal.ingredients[i].price,
  //         price_quantity_for: newVal.ingredients[i].price_quantity_for,
  //         price_unit: newVal.ingredients[i].price_unit,
  //         shipping: newVal.ingredients[i].shipping
  //       }).then((response:GenericResponse) => {
  //         // this.$data.model = model;
  //         // this.$data.ingredients = model.ingredients;
  //         // console.log(response);
  //       }).catch(error => {
  //         // console.log(error);
  //       });
  //     }

  //     RecipeCostService.updateOne(this.$data.model.id, {
  //         unit_packaging_cost: newVal.unit_packaging_cost,
  //         batch_labor_cost: newVal.batch_labor_cost,
  //         batch_overhead_cost: newVal.batch_overhead_cost,
  //         your_margin_percent: newVal.your_margin_percent,
  //         distributor_margin_percent: newVal.distributor_margin_percent,
  //         broker_margin_percent: newVal.broker_margin_percent,
  //         retailer_margin_percent: newVal.retailer_margin_percent,
  //         distributors_price: this.getDistributorPrice(),
  //         retailers_price: this.getRetailerPrice(),
  //         consumers_price: this.getConsumerPrice()
  //       }).then((response:GenericResponse) => {
  //         // this.$data.model = model;
  //         // this.$data.ingredients = model.ingredients;
  //         // console.log(response);
  //       }).catch(error => {
  //         // console.log(error);
  //       });


  //   } else {
  //     eventBus.$emit('makeToast', 'danger', 'Cantidad inválida', 'Debe ingresar una cantidad mayor o igual a cero.');
  //   }



  //   // this.$data.canContinue = (parseFloat(newVal.packages) * parseFloat(newVal.net_weight_per_package)) == parseFloat(newVal.grams);
  // }

  getIngredientBatchCost(index:number):number {
    const gramsInCost = this.toGrams(this.$data.model.ingredients[index].price_quantity_for, this.$data.model.ingredients[index].price_unit);
    const gramsInRecipe = this.$data.model.ingredients[index].grams;
    const wastePercent = 1 - (  (this.$data.model.ingredients[index].waste_percent * 1) / 100);

    if(gramsInCost > 0 && wastePercent > 0) {
        const ingredientCostRatio = (gramsInRecipe / gramsInCost);
        let amount = this.$data.model.ingredients[index].price * ingredientCostRatio;
        amount = amount / wastePercent;
        amount += ( this.$data.model.ingredients[index].shipping * ingredientCostRatio );
        return amount;
    }

    return 0;
  }

  getBatchCost():number {
    let amount = 0;
    for(let i = 0; i < this.$data.model.ingredients.length; i++) {
        amount += this.getIngredientBatchCost(i);
    }

    amount += this.$data.model.packages * 1 * this.$data.model.unit_packaging_cost;
    amount += this.$data.model.batch_labor_cost * 1;
    amount += this.$data.model.batch_overhead_cost * 1;

    return amount;
  }

  getPackageCost():number {
    const packages = this.$data.model.packages * 1;
    if(packages > 0) {
        return this.roundToDecimal(this.getBatchCost() / packages);
    }
    
    return 0;
  }

  doesNotHaveSaleChain():boolean {
      return this.hasDistributorMargin() == false && this.hasBrokerMargin() == false && this.hasRetailerMargin() == false;
  }

  hasAtLeastOneSaleChaninMargin():boolean {
      return this.hasDistributorMargin() == true || this.hasBrokerMargin() == true || this.hasRetailerMargin() == true;
  }

  hasDistributorMargin():boolean {
      return this.$data.model.distributor_margin_percent * 1 != 0;
  }

  hasBrokerMargin():boolean {
      return this.$data.model.broker_margin_percent * 1 != 0;
  }
  
  hasRetailerMargin():boolean {
      return this.$data.model.retailer_margin_percent * 1 != 0;
  }

  getDistributorPrice():number {
    // Get package base price
    let ratio = (1 - ( this.$data.model.your_margin_percent * 1 / 100 ));
    let price = this.getPackageCost();
    if(ratio > 0) {
        price = this.roundToDecimal(price / ratio);
    }

    return price;
  }

  getRetailerPrice():number {
    // Get package base price
    let price = this.getDistributorPrice();
    let ratio = 0;

    if(!this.doesNotHaveSaleChain()) {
        
        if(this.hasDistributorMargin()) { // Get price when margins until distributor
            price = this.getDistributorPrice();

            //Distirbutors
            ratio = (1 - ( this.$data.model.distributor_margin_percent * 1 / 100 ));
            price = this.roundToDecimal(price / ratio);
        } 
        
        if(this.hasBrokerMargin()) { // Get price when margins until broker

            price = this.getDistributorPrice();
        
            // Distributor
            ratio = (1 - ( this.$data.model.distributor_margin_percent * 1 / 100 ));
            price = this.roundToDecimal(price / ratio);

            // Broker
            ratio = (1 - ( this.$data.model.broker_margin_percent * 1 / 100 ));
            price = this.roundToDecimal(price / ratio);
        }
    }

    return price;
  }

  getConsumerPrice():number {
    // Get package base price
    
    let price = this.getDistributorPrice();
    let ratio = 0;

    if(!this.doesNotHaveSaleChain()) {
        
        if(this.hasDistributorMargin()) { // Get price when margins until distributor
            price = this.getDistributorPrice();
            
            // Distributor
            ratio = (1 - ( this.$data.model.distributor_margin_percent * 1 / 100 ));
            price = this.roundToDecimal(price / ratio);
        } 
        
        if(this.hasBrokerMargin()) { // Get price when margins until broker
            price = this.getDistributorPrice();

            // Distributor
            ratio = (1 - ( this.$data.model.distributor_margin_percent * 1 / 100 ));
            price = this.roundToDecimal(price / ratio);

            // Broker
            ratio = (1 - ( this.$data.model.broker_margin_percent * 1 / 100 ));
            price = this.roundToDecimal(price / ratio);
        } 
        
        if(this.hasRetailerMargin()) { // Get price when margins until retailes
            
            price = this.getDistributorPrice();

            // Distributor
            ratio = (1 - ( this.$data.model.distributor_margin_percent * 1 / 100 ));
            price = this.roundToDecimal(price / ratio);

            // Broker
            ratio = (1 - ( this.$data.model.broker_margin_percent * 1 / 100 ));
            price = this.roundToDecimal(price / ratio);
            
            // Retailer
            ratio = (1 - ( this.$data.model.retailer_margin_percent * 1 / 100 ));
            price = this.roundToDecimal(price / ratio);
            
        }

    } 

    return price;
  }

  roundToDecimal(value:number):number {
      return value * 1;//0.25 * Math.ceil(value / 0.25);
  }

  created():void {
    eventBus.$on('updateRecipeCost', (model:RecipeCost) => { 
      this.$data.model = model; 
    });
    
    this.getModel(Number.parseInt(this.$route.params.id));
  }

  getModel(id:number):void {
    RecipeCostService.getOne(id).then((model:RecipeCost) => {
      // this.$data.model = model;
      this.$store.commit('updateRecipeCost', model);
    }).catch(error => {
      this.$data.model = null;
      this.$router.push('/home');
    //   console.log(error);
    });
  }

  toGrams(quantity:number, unit:string):number {
    let gramsRatio = 0;
    switch(unit) {
        case 'lb':
            gramsRatio = 136.0;
            break;
        case 'gallon':
            gramsRatio = 3785.411784;
            break;
        case 'oz':
            gramsRatio = 28.3495;
            break;
        case 'kg':
            gramsRatio = 1000.0;
            break;
        case 'l':
            gramsRatio = 1000.0;
            break;
        case 'cup':
            gramsRatio = 143.0;
            break;
        case 'gram':
        case 'ml':
        default:
            gramsRatio = 1.0;
            break;
    }

    return quantity * gramsRatio;
  }
}
