
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import RecipeService, { FullData, Ingredient, Recipe } from '@/services/recipes.service';
import { GenericResponse } from '@/services/interceptors';
import eventBus from '@/services/eventBus';

@Component
export default class NameInput extends Vue {
  @Prop() private model!: Recipe;

  updateValue() {
    const value = this.model.name;
    RecipeService.updateOne(this.model.id, {
        name: value,
      }).then((data:FullData) => {
            // this.$store.commit('updateRecipe', data.label);
            // this.$store.commit('updateTable', data.table);
      }).catch(error => {
        console.log(error);
      });
  }
}
