<template>
    <div class="login">
        <Header title="Iniciar sesión"/>
        <div class="row">
            <div class="col-md-6 offset-md-3 my-5">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title text-primary font-weight-bold">Recuperar contraseña</h5>
                        <form action class="form" @submit.prevent="submit">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Email</label>
                                <input type="email" v-model="email" required class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                                <small id="emailHelp" class="form-text text-muted">Ingrese el email registrado en su cuenta.</small>
                            </div>
                            <b-alert v-if="error" variant="danger" show class="font-italic">Error al enviar solicitud. ¿Email no registrado?</b-alert>
                            <div v-if="success == true" class="alert alert-success my-3" role="alert">
                               Debe confirmar su correo electrónico sola solicitud enviada.
                            </div>
                            <div class="clearfix">
                                <div class="float-left">
                                    <router-link to="/">Ir a Iniciar sesión</router-link>
                                </div>
                                <button type="submit" class="btn btn-success float-right">Enviar solicitud</button>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
import AuthService from '@/services/auth.service'; // @ is an alias to /src
import Header from '@/components/Header.vue'; // @ is an alias to /src
import { required, email } from 'vuelidate/lib/validators';

export default {
    components: { Header },

    directives: { },

    data() {
        return {
            // email: 'jhackes@gmail.com',
            // password: '1Q2w3e4r-',
            email: null,
            error: false,
            success: false,
        };
    },

    validations() {
        return {
            email: { required, email }
        };
    },


    // mounted() {
    //     if(this.$router.currentRoute.path == '/password-reset-sent-success') {
    //         this.$data.success = true;
    //     }
    // },

    methods: {
        submit() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                AuthService.requestPasswordReset(this.email).then(response => {
                    this.success = true;
                    this.error = false;
                }).catch(error => {
                    this.error = true;
                    this.success = false;
                    console.log(error.response.data);
                });
            }
        }
    },
};
</script>

<style lang="scss" scoped>

</style>