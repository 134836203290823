
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import RecipeService, { FullData, Ingredient, Recipe } from '@/services/recipes.service';
import { GenericResponse } from '@/services/interceptors';
import eventBus from '@/services/eventBus';

@Component
export default class Unit extends Vue {
  @Prop() private model!: Ingredient;

  data() {
    return {
      unitOptions: [
        { value: 'gram', text: 'gramos' },
        { value: 'ml', text: 'mililitros' },
      ]
    }
  }
  
  updateValue() {
    const value = this.model.quantity;
      RecipeService.updateIngredient(this.model.recipe_id, this.model.id, {
        unit: this.model.unit,
      }).then((data:FullData) => {
            this.$store.commit('updateRecipe', data.label);
            this.$store.commit('updateTable', data.table);
      }).catch(error => {
        console.log(error);
      });
  }

  // @Watch('model.unit', { deep: false })
  // onModelChange(newVal: Recipe, oldVal: Recipe):void {
  //   if(newVal != undefined && newVal != null) {
  //     let is_model_updated = false;
  //     if(oldVal != undefined && oldVal != null) {
  //       if(newVal != oldVal) {
  //         is_model_updated = true;
  //       }
  //     }

  //     if(is_model_updated) {
  //       RecipeService.updateIngredient(this.model.recipe_id, this.model.id, {
  //           unit: this.model.unit,
  //         }).then((data:FullData) => {
  //             this.$store.commit('updateRecipe', data.label);
  //             this.$store.commit('updateTable', data.table);
  //         }).catch(error => {
  //           console.log(error);
  //         });
  //     }
  //   }
  // }

}
