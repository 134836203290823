import { counter } from '@fortawesome/fontawesome-svg-core';
import Vue from 'vue'
import Vuex from 'vuex'

import { Ingredient, Recipe, Table } from './services/recipes.service';
import { RecipeCost } from './services/recipes_cost.service';
import AuthService, { Identity } from '@/services/auth.service';
import eventBus from '@/services/eventBus';
import VueRouter from 'vue-router';


export interface AppStore {
  recipe: null | Recipe;
  table: null | Table;
  cost: null | RecipeCost;
  identity: null | Identity;
}
export interface MutationPayload {
  $router: VueRouter,
  identity: Identity
}

Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
      recipe: null,
      table:  null,
      cost: null,
      identity: null
    } as AppStore,
    actions: {
      setIdentity: (ctx, identity:null | Identity) => {
        ctx.commit('setIdentity', identity);
      },
      loadIdentity: async (ctx) => {
        try {
          const identity = await AuthService.updateIdentity();
          ctx.commit('setIdentity', identity);
        } catch(e) {
          ctx.commit('setIdentity', null);
        }
      },
      setInitialIdentity(ctx, payload:MutationPayload) {
        if( payload ) {
          ctx.commit('setIdentity', payload.identity);
          payload.$router.push('/home');
        } else {
          ctx.commit('setIdentity', null);
        }
      },
    },
    getters: {
      isAuhtenticated: (state) => {
        return state.identity != null;
      },
      getIdentity: (state) => {
        return state.identity != null ? state.identity : false;
      },
      // --
      countIngredients: (state) => {
        if(state.recipe != null && state.recipe.ingredients != undefined) {
          const ingredients = state.recipe.ingredients.filter((ri:Ingredient) => (ri.grams * 1) > 0);
          return ingredients.length;
        }

        return 0;
      },
      
    },
    mutations: {
      setIdentity(state, identity) {
        state.identity = identity;
        AuthService.setIdentity(identity);
      },
      // --
      updateRecipe(state, recipe:Recipe) {
        state.recipe = recipe;
      },
      updateTable(state, table:Table) {
        state.table = table;
      },
      updateRecipeCost(state, model:RecipeCost) {
        state.cost = model;
        eventBus.$emit('updateRecipeCost', model);
      }
    }
  });

  export default store;