<template>
    <div class="login">
        <Header title="Iniciar sesión"/>
        <div class="row">
            <div class="col-md-6 offset-md-3 my-5">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title text-primary font-weight-bold">Ingresa tus datos de acceso</h5>
                        <form action class="form" @submit.prevent="login">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Email</label>
                                <input type="email" v-model="email" required class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                                <small id="emailHelp" class="form-text text-muted">Nunca compartirémos tu email con nadie mas.</small>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Contraseña</label>
                                <input type="password" v-model="password" required class="form-control" id="exampleInputPassword1">
                            </div>
                            <b-alert v-if="error" variant="danger" show class="font-italic">Usuario o contraseña inválido</b-alert>
                            <div v-if="success == true" class="alert alert-success my-3" role="alert">
                               Se ha validado tu cuenta de correo. Por favor Inicia sesión para comenzar a utilizar tu cuenta.
                            </div>
                            <div class="clearfix">
                                <div class="float-left">
                                    <router-link to="/signup">Crear una cuenta</router-link>
                                </div>
                                <div class="float-right">
                                    <router-link to="/recuperar-constrasena"><i>Olvidé mi contraseña</i></router-link>
                                </div>
                            </div>
                            <div class="clearfix mt-3">
                                <button type="submit" class="btn btn-success float-right">Iniciar sesión</button>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
import AuthService from '@/services/auth.service'; // @ is an alias to /src
import Header from '@/components/Header.vue'; // @ is an alias to /src

export default {
    name: 'NutrilabsLogin',

    components: { Header },

    directives: { },

    data() {
        return {
            // email: 'jhackes@gmail.com',
            // password: '1Q2w3e4r-',
            email: null,
            password: null,
            error: false,
            success: false,
        };
    },

    mounted() {
        if(this.$router.currentRoute.path == '/email-validation-success') {
            this.$data.success = true;
        }
    },

    methods: {
        login() {
            AuthService.login(this.email, this.password).then(response => {
                this.error = false;
                this.$store.dispatch('setInitialIdentity', {
                    $router: this.$router,
                    identity: response.data
                });
                //this.$router.push('/home');
            }).catch(error => {
                this.error = true;
                this.success = false;
                // console.log(error.response.data);
            });
        }
    },
};
</script>

<style lang="scss" scoped>

</style>