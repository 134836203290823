var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-row',{staticClass:"bg-light"},[_c('b-col',{attrs:{"cols":"12"}},[_c('Header',{attrs:{"title":_vm.model.name}})],1),_c('b-col',{staticClass:"px-0",attrs:{"cols":"2"}},[_c('Sidebar',{attrs:{"current":"ingredients"}})],1),_c('b-col',{attrs:{"cols":"10"}},[_c('b-row',[_c('b-col',{staticClass:"px-0"},[_c('div',{staticClass:"bg-white p-3"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('h3',{staticClass:"my-2"},[_vm._v("Tu ingrediente, medida y peso")]),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"id":"input-group-name","label":"Nombre:","label-for":"input-name"}},[_c('b-form-input',{class:{ 
                            'is-invalid': _vm.$v.model.name.$dirty && _vm.$v.model.name.$error,
                            'is-valid': _vm.$v.model.name.$dirty && !_vm.$v.model.name.$error, 
                            },attrs:{"id":"input-name","type":"text"},on:{"input":_vm.$v.model.name.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"id":"input-group-servin_size_desc_1","label":"Medida a utilizar:","label-for":"input-servin_size_desc_1"}},[_c('b-form-input',{class:{ 
                            'is-invalid': _vm.$v.model.serving_size_desc_1.$dirty && _vm.$v.model.serving_size_desc_1.$error,
                            'is-valid': _vm.$v.model.serving_size_desc_1.$dirty && !_vm.$v.model.serving_size_desc_1.$error, 
                            },attrs:{"id":"input-servin_size_desc_1","type":"text"},on:{"input":_vm.$v.model.serving_size_desc_1.$touch},model:{value:(_vm.model.serving_size_desc_1),callback:function ($$v) {_vm.$set(_vm.model, "serving_size_desc_1", $$v)},expression:"model.serving_size_desc_1"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"id":"input-group-serving_size_weight_1_g","label":"Peso en gramos / mililitros:","label-for":"input-serving_size_weight_1_g","description":"Los nutrientes a capturar serán en base a este peso."}},[_c('b-form-input',{class:{ 
                            'is-invalid': _vm.$v.model.serving_size_weight_1_g.$dirty && _vm.$v.model.serving_size_weight_1_g.$error,
                            'is-valid': _vm.$v.model.serving_size_weight_1_g.$dirty && !_vm.$v.model.serving_size_weight_1_g.$error, 
                            },attrs:{"id":"input-serving_size_weight_1_g","type":"text"},on:{"input":_vm.$v.model.serving_size_weight_1_g.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.serving_size_weight_1_g),callback:function ($$v) {_vm.$set(_vm.model, "serving_size_weight_1_g", $$v)},expression:"model.serving_size_weight_1_g"}})],1)],1)],1),_c('h3',{staticClass:"my-2"},[_vm._v("Declaración nutrimental")]),_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('h4',{staticClass:"text-primary"},[_vm._v("Nutrimentos obligatorios")]),_c('b-form-group',{attrs:{"id":"input-group-calories","label":"Contenido energético Kilocaloría ( kcal ):","label-for":"input-calories"}},[_c('b-form-input',{class:{ 
                            'is-invalid': _vm.$v.model.calories.$dirty && _vm.$v.model.calories.$error,
                            'is-valid': _vm.$v.model.calories.$dirty && !_vm.$v.model.calories.$error, 
                            },attrs:{"id":"input-calories","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.calories.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.calories),callback:function ($$v) {_vm.$set(_vm.model, "calories", $$v)},expression:"model.calories"}})],1),_c('b-form-group',{attrs:{"id":"input-group-calories_kj","label":"Contenido energético KiloJoule ( kJ ):","label-for":"input-calories_kj"}},[_c('b-form-input',{class:{ 
                            'is-invalid': _vm.$v.model.calories_kj.$dirty && _vm.$v.model.calories_kj.$error,
                            'is-valid': _vm.$v.model.calories_kj.$dirty && !_vm.$v.model.calories_kj.$error, 
                            },attrs:{"id":"input-calories_kj","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.calories_kj.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.calories_kj),callback:function ($$v) {_vm.$set(_vm.model, "calories_kj", $$v)},expression:"model.calories_kj"}})],1),_c('b-form-group',{attrs:{"id":"input-group-protein_g","label":"Proteínas (g):","label-for":"input-protein_g"}},[_c('b-form-input',{class:{ 
                            'is-invalid': _vm.$v.model.protein_g.$dirty && _vm.$v.model.protein_g.$error,
                            'is-valid': _vm.$v.model.protein_g.$dirty && !_vm.$v.model.protein_g.$error, 
                            },attrs:{"id":"input-protein_g","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.protein_g.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.protein_g),callback:function ($$v) {_vm.$set(_vm.model, "protein_g", $$v)},expression:"model.protein_g"}})],1),_c('b-form-group',{attrs:{"id":"input-group-fat_g","label":"Grasas totales (g):","label-for":"input-fat_g"}},[_c('b-form-input',{class:{ 
                            'is-invalid': _vm.$v.model.fat_g.$dirty && _vm.$v.model.fat_g.$error,
                            'is-valid': _vm.$v.model.fat_g.$dirty && !_vm.$v.model.fat_g.$error, 
                            },attrs:{"id":"input-fat_g","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.fat_g.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.fat_g),callback:function ($$v) {_vm.$set(_vm.model, "fat_g", $$v)},expression:"model.fat_g"}})],1),_c('b-form-group',{attrs:{"id":"input-group-saturaded_fat_g","label":"Grasas saturadas (g):","label-for":"input-saturaded_fat_g"}},[_c('b-form-input',{class:{ 
                            'is-invalid': _vm.$v.model.saturaded_fat_g.$dirty && _vm.$v.model.saturaded_fat_g.$error,
                            'is-valid': _vm.$v.model.saturaded_fat_g.$dirty && !_vm.$v.model.saturaded_fat_g.$error, 
                            },attrs:{"id":"input-saturaded_fat_g","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.saturaded_fat_g.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.saturaded_fat_g),callback:function ($$v) {_vm.$set(_vm.model, "saturaded_fat_g", $$v)},expression:"model.saturaded_fat_g"}})],1),_c('b-form-group',{attrs:{"id":"input-group-trans_fat_mg","label":"Grasas trans (mg):","label-for":"input-trans_fat_mg"}},[_c('b-form-input',{class:{ 
                            'is-invalid': _vm.$v.model.trans_fat_mg.$dirty && _vm.$v.model.trans_fat_mg.$error,
                            'is-valid': _vm.$v.model.trans_fat_mg.$dirty && !_vm.$v.model.trans_fat_mg.$error, 
                            },attrs:{"id":"input-trans_fat_mg","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.trans_fat_mg.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.trans_fat_mg),callback:function ($$v) {_vm.$set(_vm.model, "trans_fat_mg", $$v)},expression:"model.trans_fat_mg"}})],1),_c('b-form-group',{attrs:{"id":"input-group-carbohydrate_g","label":"Hidratos de carbono disponibles (g):","label-for":"input-carbohydrate_g"}},[_c('b-form-input',{class:{ 
                            'is-invalid': _vm.$v.model.carbohydrate_g.$dirty && _vm.$v.model.carbohydrate_g.$error,
                            'is-valid': _vm.$v.model.carbohydrate_g.$dirty && !_vm.$v.model.carbohydrate_g.$error, 
                            },attrs:{"id":"input-carbohydrate_g","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.carbohydrate_g.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.carbohydrate_g),callback:function ($$v) {_vm.$set(_vm.model, "carbohydrate_g", $$v)},expression:"model.carbohydrate_g"}})],1),_c('b-form-group',{attrs:{"id":"input-group-sugar_g","label":"Azúcares (g):","label-for":"input-sugar_g"}},[_c('b-form-input',{class:{ 
                            'is-invalid': _vm.$v.model.sugar_g.$dirty && _vm.$v.model.sugar_g.$error,
                            'is-valid': _vm.$v.model.sugar_g.$dirty && !_vm.$v.model.sugar_g.$error, 
                            },attrs:{"id":"input-sugar_g","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.sugar_g.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.sugar_g),callback:function ($$v) {_vm.$set(_vm.model, "sugar_g", $$v)},expression:"model.sugar_g"}})],1),_c('b-form-group',{attrs:{"id":"input-group-added_sugar_g","label":"Azúcares añadidos (g):","label-for":"input-added_sugar_g"}},[_c('b-form-input',{class:{ 
                            'is-invalid': _vm.$v.model.added_sugar_g.$dirty && _vm.$v.model.added_sugar_g.$error,
                            'is-valid': _vm.$v.model.added_sugar_g.$dirty && !_vm.$v.model.added_sugar_g.$error, 
                            },attrs:{"id":"input-added_sugar_g","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.added_sugar_g.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.added_sugar_g),callback:function ($$v) {_vm.$set(_vm.model, "added_sugar_g", $$v)},expression:"model.added_sugar_g"}})],1),_c('b-form-group',{attrs:{"id":"input-group-dietary_fiber_g","label":"Fibra dietética (g):","label-for":"input-dietary_fiber_g"}},[_c('b-form-input',{class:{ 
                            'is-invalid': _vm.$v.model.dietary_fiber_g.$dirty && _vm.$v.model.dietary_fiber_g.$error,
                            'is-valid': _vm.$v.model.dietary_fiber_g.$dirty && !_vm.$v.model.dietary_fiber_g.$error, 
                            },attrs:{"id":"input-dietary_fiber_g","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.dietary_fiber_g.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.dietary_fiber_g),callback:function ($$v) {_vm.$set(_vm.model, "dietary_fiber_g", $$v)},expression:"model.dietary_fiber_g"}})],1),_c('b-form-group',{attrs:{"id":"input-group-sodium_mg","label":"Sodio (mg):","label-for":"input-sodium_mg"}},[_c('b-form-input',{class:{ 
                            'is-invalid': _vm.$v.model.sodium_mg.$dirty && _vm.$v.model.sodium_mg.$error,
                            'is-valid': _vm.$v.model.sodium_mg.$dirty && !_vm.$v.model.sodium_mg.$error, 
                            },attrs:{"id":"input-sodium_mg","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.sodium_mg.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.sodium_mg),callback:function ($$v) {_vm.$set(_vm.model, "sodium_mg", $$v)},expression:"model.sodium_mg"}})],1)],1),_c('b-col',{attrs:{"cols":"7"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"text-primary"},[_vm._v("Nutrimentos voluntarios")])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"input-group-mono_fat_g","label":"Grasas monoinsaturadas (g):","label-for":"input-mono_fat_g"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.mono_fat_g.$dirty && _vm.$v.model.mono_fat_g.$error,
                                    'is-valid': _vm.$v.model.mono_fat_g.$dirty && !_vm.$v.model.mono_fat_g.$error, 
                                    },attrs:{"id":"input-mono_fat_g","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.mono_fat_g.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.mono_fat_g),callback:function ($$v) {_vm.$set(_vm.model, "mono_fat_g", $$v)},expression:"model.mono_fat_g"}})],1),_c('b-form-group',{attrs:{"id":"input-group-polii_fat_g","label":"Grasas poliinsaturadas (g):","label-for":"input-polii_fat_g"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.polii_fat_g.$dirty && _vm.$v.model.polii_fat_g.$error,
                                    'is-valid': _vm.$v.model.polii_fat_g.$dirty && !_vm.$v.model.polii_fat_g.$error, 
                                    },attrs:{"id":"input-polii_fat_g","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.polii_fat_g.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.polii_fat_g),callback:function ($$v) {_vm.$set(_vm.model, "polii_fat_g", $$v)},expression:"model.polii_fat_g"}})],1),_c('b-form-group',{attrs:{"id":"input-group-cholesterol_mg","label":"Colesterol (mg):","label-for":"input-cholesterol_mg"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.cholesterol_mg.$dirty && _vm.$v.model.cholesterol_mg.$error,
                                    'is-valid': _vm.$v.model.cholesterol_mg.$dirty && !_vm.$v.model.cholesterol_mg.$error, 
                                    },attrs:{"id":"input-cholesterol_mg","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.cholesterol_mg.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.cholesterol_mg),callback:function ($$v) {_vm.$set(_vm.model, "cholesterol_mg", $$v)},expression:"model.cholesterol_mg"}})],1),_c('b-form-group',{attrs:{"id":"input-group-vitamin_a_ug","label":"Vitamina A (μg):","label-for":"input-vitamin_a_ug"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.vitamin_a_ug.$dirty && _vm.$v.model.vitamin_a_ug.$error,
                                    'is-valid': _vm.$v.model.vitamin_a_ug.$dirty && !_vm.$v.model.vitamin_a_ug.$error, 
                                    },attrs:{"id":"input-vitamin_a_ug","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.vitamin_a_ug.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.vitamin_a_ug),callback:function ($$v) {_vm.$set(_vm.model, "vitamin_a_ug", $$v)},expression:"model.vitamin_a_ug"}})],1),_c('b-form-group',{attrs:{"id":"input-group-thiamine_ug","label":"Vitamina B1 (Tiamina) (μg):","label-for":"input-thiamine_ug"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.thiamine_ug.$dirty && _vm.$v.model.thiamine_ug.$error,
                                    'is-valid': _vm.$v.model.thiamine_ug.$dirty && !_vm.$v.model.thiamine_ug.$error, 
                                    },attrs:{"id":"input-thiamine_ug","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.thiamine_ug.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.thiamine_ug),callback:function ($$v) {_vm.$set(_vm.model, "thiamine_ug", $$v)},expression:"model.thiamine_ug"}})],1),_c('b-form-group',{attrs:{"id":"input-group-riboflavin_ug","label":"Vitamina B2 (Riboflavina) (μg):","label-for":"input-riboflavin_ug"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.riboflavin_ug.$dirty && _vm.$v.model.riboflavin_ug.$error,
                                    'is-valid': _vm.$v.model.riboflavin_ug.$dirty && !_vm.$v.model.riboflavin_ug.$error, 
                                    },attrs:{"id":"input-riboflavin_ug","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.riboflavin_ug.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.riboflavin_ug),callback:function ($$v) {_vm.$set(_vm.model, "riboflavin_ug", $$v)},expression:"model.riboflavin_ug"}})],1),_c('b-form-group',{attrs:{"id":"input-group-vitamin_b6_mg","label":"Vitamina B6 (μg):","label-for":"input-vitamin_b6_mg"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.vitamin_b6_mg.$dirty && _vm.$v.model.vitamin_b6_mg.$error,
                                    'is-valid': _vm.$v.model.vitamin_b6_mg.$dirty && !_vm.$v.model.vitamin_b6_mg.$error, 
                                    },attrs:{"id":"input-vitamin_b6_mg","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.vitamin_b6_mg.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.vitamin_b6_mg),callback:function ($$v) {_vm.$set(_vm.model, "vitamin_b6_mg", $$v)},expression:"model.vitamin_b6_mg"}})],1),_c('b-form-group',{attrs:{"id":"input-group-niacin_mg","label":"Niacina (Vitamina B3) (mg):","label-for":"input-niacin_mg"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.niacin_mg.$dirty && _vm.$v.model.niacin_mg.$error,
                                    'is-valid': _vm.$v.model.niacin_mg.$dirty && !_vm.$v.model.niacin_mg.$error, 
                                    },attrs:{"id":"input-niacin_mg","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.niacin_mg.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.niacin_mg),callback:function ($$v) {_vm.$set(_vm.model, "niacin_mg", $$v)},expression:"model.niacin_mg"}})],1),_c('b-form-group',{attrs:{"id":"input-group-folic_acid_ug","label":"Ácido fólico (μg):","label-for":"input-folic_acid_ug"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.folic_acid_ug.$dirty && _vm.$v.model.folic_acid_ug.$error,
                                    'is-valid': _vm.$v.model.folic_acid_ug.$dirty && !_vm.$v.model.folic_acid_ug.$error, 
                                    },attrs:{"id":"input-folic_acid_ug","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.folic_acid_ug.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.folic_acid_ug),callback:function ($$v) {_vm.$set(_vm.model, "folic_acid_ug", $$v)},expression:"model.folic_acid_ug"}})],1),_c('b-form-group',{attrs:{"id":"input-group-vitamin_b12_ug","label":"Vitamina B12 (μg):","label-for":"input-vitamin_b12_ug"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.vitamin_b12_ug.$dirty && _vm.$v.model.vitamin_b12_ug.$error,
                                    'is-valid': _vm.$v.model.vitamin_b12_ug.$dirty && !_vm.$v.model.vitamin_b12_ug.$error, 
                                    },attrs:{"id":"input-vitamin_b12_ug","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.vitamin_b12_ug.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.vitamin_b12_ug),callback:function ($$v) {_vm.$set(_vm.model, "vitamin_b12_ug", $$v)},expression:"model.vitamin_b12_ug"}})],1),_c('b-form-group',{attrs:{"id":"input-group-vitamin_c_mg","label":"Vitamina C (mg):","label-for":"input-vitamin_c_mg"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.vitamin_c_mg.$dirty && _vm.$v.model.vitamin_c_mg.$error,
                                    'is-valid': _vm.$v.model.vitamin_c_mg.$dirty && !_vm.$v.model.vitamin_c_mg.$error, 
                                    },attrs:{"id":"input-vitamin_c_mg","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.vitamin_c_mg.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.vitamin_c_mg),callback:function ($$v) {_vm.$set(_vm.model, "vitamin_c_mg", $$v)},expression:"model.vitamin_c_mg"}})],1),_c('b-form-group',{attrs:{"id":"input-group-vitamin_d_ug","label":"Vitamina D (μg):","label-for":"input-vitamin_d_ug"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.vitamin_d_ug.$dirty && _vm.$v.model.vitamin_d_ug.$error,
                                    'is-valid': _vm.$v.model.vitamin_d_ug.$dirty && !_vm.$v.model.vitamin_d_ug.$error, 
                                    },attrs:{"id":"input-vitamin_d_ug","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.vitamin_d_ug.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.vitamin_d_ug),callback:function ($$v) {_vm.$set(_vm.model, "vitamin_d_ug", $$v)},expression:"model.vitamin_d_ug"}})],1),_c('b-form-group',{attrs:{"id":"input-group-vitamin_e_mg","label":"Vitamina E (mg):","label-for":"input-vitamin_e_mg"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.vitamin_e_mg.$dirty && _vm.$v.model.vitamin_e_mg.$error,
                                    'is-valid': _vm.$v.model.vitamin_e_mg.$dirty && !_vm.$v.model.vitamin_e_mg.$error, 
                                    },attrs:{"id":"input-vitamin_e_mg","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.vitamin_e_mg.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.vitamin_e_mg),callback:function ($$v) {_vm.$set(_vm.model, "vitamin_e_mg", $$v)},expression:"model.vitamin_e_mg"}})],1),_c('b-form-group',{attrs:{"id":"input-group-vitamin_k_ug","label":"Vitamina K (μg):","label-for":"input-vitamin_k_ug"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.vitamin_k_ug.$dirty && _vm.$v.model.vitamin_k_ug.$error,
                                    'is-valid': _vm.$v.model.vitamin_k_ug.$dirty && !_vm.$v.model.vitamin_k_ug.$error, 
                                    },attrs:{"id":"input-vitamin_k_ug","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.vitamin_k_ug.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.vitamin_k_ug),callback:function ($$v) {_vm.$set(_vm.model, "vitamin_k_ug", $$v)},expression:"model.vitamin_k_ug"}})],1),_c('b-form-group',{attrs:{"id":"input-group-pantothenic_acid_mg","label":"Ácido pantoténico (Vitamina B5) (mg):","label-for":"input-pantothenic_acid_mg"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.pantothenic_acid_mg.$dirty && _vm.$v.model.pantothenic_acid_mg.$error,
                                    'is-valid': _vm.$v.model.pantothenic_acid_mg.$dirty && !_vm.$v.model.pantothenic_acid_mg.$error, 
                                    },attrs:{"id":"input-pantothenic_acid_mg","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.pantothenic_acid_mg.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.pantothenic_acid_mg),callback:function ($$v) {_vm.$set(_vm.model, "pantothenic_acid_mg", $$v)},expression:"model.pantothenic_acid_mg"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"input-group-calcium_mg","label":"Calcio (mg):","label-for":"input-calcium_mg"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.calcium_mg.$dirty && _vm.$v.model.calcium_mg.$error,
                                    'is-valid': _vm.$v.model.calcium_mg.$dirty && !_vm.$v.model.calcium_mg.$error, 
                                    },attrs:{"id":"input-calcium_mg","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.calcium_mg.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.calcium_mg),callback:function ($$v) {_vm.$set(_vm.model, "calcium_mg", $$v)},expression:"model.calcium_mg"}})],1),_c('b-form-group',{attrs:{"id":"input-group-copper_ug","label":"Cobre (μg):","label-for":"input-copper_ug"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.copper_ug.$dirty && _vm.$v.model.copper_ug.$error,
                                    'is-valid': _vm.$v.model.copper_ug.$dirty && !_vm.$v.model.copper_ug.$error, 
                                    },attrs:{"id":"input-copper_ug","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.copper_ug.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.copper_ug),callback:function ($$v) {_vm.$set(_vm.model, "copper_ug", $$v)},expression:"model.copper_ug"}})],1),_c('b-form-group',{attrs:{"id":"input-group-chromium_ug","label":"Cromo (μg):","label-for":"input-chromium_ug"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.chromium_ug.$dirty && _vm.$v.model.chromium_ug.$error,
                                    'is-valid': _vm.$v.model.chromium_ug.$dirty && !_vm.$v.model.chromium_ug.$error, 
                                    },attrs:{"id":"input-chromium_ug","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.chromium_ug.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.chromium_ug),callback:function ($$v) {_vm.$set(_vm.model, "chromium_ug", $$v)},expression:"model.chromium_ug"}})],1),_c('b-form-group',{attrs:{"id":"input-group-fluor_mg","label":"Flúor (mg):","label-for":"input-fluor_mg"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.fluor_mg.$dirty && _vm.$v.model.fluor_mg.$error,
                                    'is-valid': _vm.$v.model.fluor_mg.$dirty && !_vm.$v.model.fluor_mg.$error, 
                                    },attrs:{"id":"input-fluor_mg","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.fluor_mg.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.fluor_mg),callback:function ($$v) {_vm.$set(_vm.model, "fluor_mg", $$v)},expression:"model.fluor_mg"}})],1),_c('b-form-group',{attrs:{"id":"input-group-phosphorus_mg","label":"Fósforo (mg):","label-for":"input-phosphorus_mg"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.phosphorus_mg.$dirty && _vm.$v.model.phosphorus_mg.$error,
                                    'is-valid': _vm.$v.model.phosphorus_mg.$dirty && !_vm.$v.model.phosphorus_mg.$error, 
                                    },attrs:{"id":"input-phosphorus_mg","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.phosphorus_mg.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.phosphorus_mg),callback:function ($$v) {_vm.$set(_vm.model, "phosphorus_mg", $$v)},expression:"model.phosphorus_mg"}})],1),_c('b-form-group',{attrs:{"id":"input-group-iron_mg","label":"Hierro (mg):","label-for":"input-iron_mg"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.iron_mg.$dirty && _vm.$v.model.iron_mg.$error,
                                    'is-valid': _vm.$v.model.iron_mg.$dirty && !_vm.$v.model.iron_mg.$error, 
                                    },attrs:{"id":"input-iron_mg","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.iron_mg.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.iron_mg),callback:function ($$v) {_vm.$set(_vm.model, "iron_mg", $$v)},expression:"model.iron_mg"}})],1),_c('b-form-group',{attrs:{"id":"input-group-magnesium_mg","label":"Magnesio (mg):","label-for":"input-magnesium_mg"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.magnesium_mg.$dirty && _vm.$v.model.magnesium_mg.$error,
                                    'is-valid': _vm.$v.model.magnesium_mg.$dirty && !_vm.$v.model.magnesium_mg.$error, 
                                    },attrs:{"id":"input-magnesium_mg","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.magnesium_mg.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.magnesium_mg),callback:function ($$v) {_vm.$set(_vm.model, "magnesium_mg", $$v)},expression:"model.magnesium_mg"}})],1),_c('b-form-group',{attrs:{"id":"input-group-selenium_ug","label":"Selenio (μg):","label-for":"input-selenium_ug"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.selenium_ug.$dirty && _vm.$v.model.selenium_ug.$error,
                                    'is-valid': _vm.$v.model.selenium_ug.$dirty && !_vm.$v.model.selenium_ug.$error, 
                                    },attrs:{"id":"input-selenium_ug","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.selenium_ug.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.selenium_ug),callback:function ($$v) {_vm.$set(_vm.model, "selenium_ug", $$v)},expression:"model.selenium_ug"}})],1),_c('b-form-group',{attrs:{"id":"input-group-iodine_ug","label":"Yodo (μg):","label-for":"input-iodine_ug"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.iodine_ug.$dirty && _vm.$v.model.iodine_ug.$error,
                                    'is-valid': _vm.$v.model.iodine_ug.$dirty && !_vm.$v.model.iodine_ug.$error, 
                                    },attrs:{"id":"input-iodine_ug","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.iodine_ug.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.iodine_ug),callback:function ($$v) {_vm.$set(_vm.model, "iodine_ug", $$v)},expression:"model.iodine_ug"}})],1),_c('b-form-group',{attrs:{"id":"input-group-zinc_mg","label":"Zinc (mg):","label-for":"input-zinc_mg"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.zinc_mg.$dirty && _vm.$v.model.zinc_mg.$error,
                                    'is-valid': _vm.$v.model.zinc_mg.$dirty && !_vm.$v.model.zinc_mg.$error, 
                                    },attrs:{"id":"input-zinc_mg","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.zinc_mg.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.zinc_mg),callback:function ($$v) {_vm.$set(_vm.model, "zinc_mg", $$v)},expression:"model.zinc_mg"}})],1),_c('b-form-group',{attrs:{"id":"input-group-potassium_mg","label":"Potasio (mg):","label-for":"input-potassium_mg"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.potassium_mg.$dirty && _vm.$v.model.potassium_mg.$error,
                                    'is-valid': _vm.$v.model.potassium_mg.$dirty && !_vm.$v.model.potassium_mg.$error, 
                                    },attrs:{"id":"input-potassium_mg","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.potassium_mg.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.potassium_mg),callback:function ($$v) {_vm.$set(_vm.model, "potassium_mg", $$v)},expression:"model.potassium_mg"}})],1),_c('b-form-group',{attrs:{"id":"input-group-manganese_mg","label":"Manganeso (mg):","label-for":"input-manganese_mg"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.manganese_mg.$dirty && _vm.$v.model.manganese_mg.$error,
                                    'is-valid': _vm.$v.model.manganese_mg.$dirty && !_vm.$v.model.manganese_mg.$error, 
                                    },attrs:{"id":"input-manganese_mg","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.manganese_mg.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.manganese_mg),callback:function ($$v) {_vm.$set(_vm.model, "manganese_mg", $$v)},expression:"model.manganese_mg"}})],1),_c('b-form-group',{attrs:{"id":"input-group-starch_g","label":"Almidón (g):","label-for":"input-starch_g"}},[_c('b-form-input',{class:{ 
                                    'is-invalid': _vm.$v.model.starch_g.$dirty && _vm.$v.model.starch_g.$error,
                                    'is-valid': _vm.$v.model.starch_g.$dirty && !_vm.$v.model.starch_g.$error, 
                                    },attrs:{"id":"input-starch_g","type":"text","step":"0.01","min":"0.00"},on:{"input":_vm.$v.model.starch_g.$touch},nativeOn:{"focus":function($event){return $event.target.select()},"click":function($event){return $event.target.select()}},model:{value:(_vm.model.starch_g),callback:function ($$v) {_vm.$set(_vm.model, "starch_g", $$v)},expression:"model.starch_g"}})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":!_vm.canContinue && !_vm.$v.$invalid}},[_vm._v("Guardar "),_c('BIconCheck')],1)],1)],1)],1)],1)])],1),_c('b-row',[(_vm.hasSugarError)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_c('b',[_vm._v("IMPORTANTE.")]),_vm._v(" Los azúcares totales deben ser mayores o iguales a los azúcares añadidos.")])],1):_vm._e(),(_vm.hasCarbohydrateError)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_c('b',[_vm._v("IMPORTANTE.")]),_vm._v(" La suma de los azúcares totales y almidón debe ser menor a los hidratos de carbono disponibles.")])],1):_vm._e(),(_vm.hasWeightError)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_c('b',[_vm._v("IMPORTANTE.")]),_vm._v(" La suma de nutrientes excede el peso de la medida especificada.")])],1):_vm._e(),(_vm.hasFatsError)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_c('b',[_vm._v("IMPORTANTE.")]),_vm._v(" La suma de los tipos de grasas debe ser menor o igual a las grasas totales.")])],1):_vm._e(),(_vm.hasSaturedFatsError)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_c('b',[_vm._v("IMPORTANTE.")]),_vm._v(" La suma de grasas monoinsaturadas y poliinsaturadas no debe de ser mayor al total de grasas saturadas.")])],1):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('Footer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }