
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Identity } from '@/services/auth.service';
import AccountOrders from '@/components/my-account/AccountOrders.vue';
import Products from '@/components/my-account/Products.vue';
import AuthService from '@/services/auth.service'; // @ is an alias to /src

@Component(
    {
        components: { AccountOrders, Products }
    }
)
export default class Profile extends Vue {
    // @Prop() private identity!: Identity;

    data() {
        return {
        };
    }

    get isAuthenticated() {
        return this.$store.getters.isAuhtenticated;
    }

    get identity() {
        return this.$store.getters.getIdentity;
    }


    // created() {
    //     AuthService.updateIdentity().then(response => {
    //         AuthService.setIdentity(response);
    //     });
    // }
}
