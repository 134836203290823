
import { Component, Watch, Vue } from 'vue-property-decorator';
import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Info from '@/components/Info.vue';
import Step from '@/components/recipe-detail/Step.vue';

import CustomerIngredientsService, { CustomerIngredient } from '@/services/customer_ingredients.service';
import { GenericResponse } from '@/services/interceptors';

import { Validate, Validations } from 'vuelidate-property-decorators';
import { required, minValue, minLength } from 'vuelidate/lib/validators';
import eventBus from '@/services/eventBus';

@Component({
  components: {
    Header, 
    Footer, 
    Sidebar,
    Info, 
    Step,
  },
})
export default class CustomIngredientUpdate extends Vue {
    data() {
        return {
            model: {
                id: 0,
                status: 0,
                user_id: 0,
                type: 0,
                subtype: 0,
                verified: 0,
                is_public: 0,
                name: '',
                manufacturer: null,
                ingredient_list: null,
                serving_size_desc_1: '',
                serving_size_weight_1_g: 0,
                serving_size_desc_2: null,
                serving_size_weight_2_g: 0,
                serving_size_desc_3: null,
                serving_size_weight_3_g: 0,
                serving_size_desc_4: null,
                serving_size_weight_4_g: 0,
                serving_size_desc_5: null,
                serving_size_weight_5_g: 0,
                serving_size_desc_6: null,
                serving_size_weight_6_g: 0,
                serving_size_desc_7: null,
                serving_size_weight_7_g: 0,
                serving_size_desc_8: null,
                serving_size_weight_8_g: 0,
                serving_size_desc_9: null,
                serving_size_weight_9_g: 0,
                serving_size_desc_10: null,
                serving_size_weight_1_g0: 0,
                data_source_url: null,
                picture_url: null,
                calories: 0,
                fat_g: 0,
                saturaded_fat_g: 0,
                trans_fat_mg: 0,
                cholesterol_mg: 0,
                sodium_mg: 0,
                carbohydrate_g: 0,
                dietary_fiber_g: 0,
                sugar_g: 0,
                added_sugar_g: 0,
                protein_g: 0,
                vitamin_a_ug: 0,
                vitamin_b1_ug: 0,
                vitamin_b2_ug: 0,
                niacin_mg: 0,
                folic_acid_ug: 0,
                vitamin_b12_ug: 0,
                vitamin_d_ug: 0,
                vitamin_k_ug: 0,
                pantothenic_acid_mg: 0,
                calcium_mg: 0,
                chromium_ug: 0,
                fluor_mg: 0,
                phosphorus_mg: 0,
                iron_mg: 0,
                magnesium_mg: 0,
                selenium_ug: 0,
                iodine_ug: 0,
                created_at: 0,
                updated_at: 0,
                account_id: 0,
                calories_kj: 0,
                mono_fat_g: 0,
                polii_fat_g: 0,
                zinc_mg: 0,
                starch_g: 0,
                copper_ug: 0,
                manganese_mg: 0,
                potassium_mg: 0,
                riboflavin_ug: 0,
                thiamine_ug: 0,
                vitamin_c_mg: 0,
                vitamin_e_mg: 0,
                vitamin_b6_mg: 0,
                recipes: []
            },
            hasSugarError: false,
            hasCarbohydrateError: false,
            hasWeightError: false,
            hasFatsError: false,
            hasSaturedFatsError: false,
            canContinue: false
        };
    }

    @Validations()
    validations() {
        return {
            model: {
                    name: { required, minLength: minLength(1) },
                    serving_size_desc_1: { required, minLength: minLength(1) },
                    serving_size_weight_1_g: { required, minValue: minValue(0) },
                    calories: { required, minValue: minValue(0) },
                    fat_g: { required, minValue: minValue(0) },
                    saturaded_fat_g: { required, minValue: minValue(0) },
                    trans_fat_mg: { required, minValue: minValue(0) },
                    cholesterol_mg: { required, minValue: minValue(0) },
                    sodium_mg: { required, minValue: minValue(0) },
                    carbohydrate_g: { required, minValue: minValue(0) },
                    dietary_fiber_g: { required, minValue: minValue(0) },
                    sugar_g: { required, minValue: minValue(0) },
                    added_sugar_g: { required, minValue: minValue(0) },
                    protein_g: { required, minValue: minValue(0) },
                    vitamin_a_ug: { required, minValue: minValue(0) },
                    vitamin_b1_ug: { required, minValue: minValue(0) },
                    vitamin_b2_ug: { required, minValue: minValue(0) },
                    niacin_mg: { required, minValue: minValue(0) },
                    folic_acid_ug: { required, minValue: minValue(0) },
                    vitamin_b12_ug: { required, minValue: minValue(0) },
                    vitamin_d_ug: { required, minValue: minValue(0) },
                    vitamin_k_ug: { required, minValue: minValue(0) },
                    pantothenic_acid_mg: { required, minValue: minValue(0) },
                    calcium_mg: { required, minValue: minValue(0) },
                    chromium_ug: { required, minValue: minValue(0) },
                    fluor_mg: { required, minValue: minValue(0) },
                    phosphorus_mg: { required, minValue: minValue(0) },
                    iron_mg: { required, minValue: minValue(0) },
                    magnesium_mg: { required, minValue: minValue(0) },
                    selenium_ug: { required, minValue: minValue(0) },
                    iodine_ug: { required, minValue: minValue(0) },
                    calories_kj: { required, minValue: minValue(0) },
                    mono_fat_g: { required, minValue: minValue(0) },
                    polii_fat_g: { required, minValue: minValue(0) },
                    zinc_mg: { required, minValue: minValue(0) },
                    starch_g: { required, minValue: minValue(0) },
                    copper_ug: { required, minValue: minValue(0) },
                    manganese_mg: { required, minValue: minValue(0) },
                    potassium_mg: { required, minValue: minValue(0) },
                    riboflavin_ug: { required, minValue: minValue(0) },
                    thiamine_ug: { required, minValue: minValue(0) },
                    vitamin_c_mg: { required, minValue: minValue(0) },
                    vitamin_e_mg: { required, minValue: minValue(0) },
                    vitamin_b6_mg: { required, minValue: minValue(0) },
            }
        };
    }

    @Watch('model', { deep: true })
    onModelChange(newVal: CustomerIngredient):void {
    // save or monitor updates

        this.$data.hasSugarError = (newVal.added_sugar_g * 1) > (newVal.sugar_g * 1);
        this.$data.hasCarbohydrateError = (newVal.sugar_g * 1 + newVal.starch_g * 1) > newVal.carbohydrate_g * 1;
        this.$data.hasWeightError = ( newVal.fat_g * 1 + newVal.carbohydrate_g * 1 + newVal.protein_g * 1 + newVal.dietary_fiber_g  * 1) > newVal.serving_size_weight_1_g * 1;
        this.$data.hasFatsError = ( newVal.saturaded_fat_g * 1 + newVal.mono_fat_g * 1 + newVal.polii_fat_g * 1 + ((newVal.cholesterol_mg * 1 + newVal.trans_fat_mg * 1) / 1000) ) > newVal.fat_g * 1;
        // this.$data.hasSaturedFatsError =  (newVal.saturaded_fat_g * 1) < (newVal.mono_fat_g * 1 + newVal.polii_fat_g * 1);

        this.$data.canContinue = !this.$data.hasSugarError && !this.$data.hasCarbohydrateError && !this.$data.hasWeightError && !this.$data.hasFatsError && !this.$data.hasSaturedFatsError;
    }

    created():void {
        // this.getModel(Number.parseInt(this.$route.params.id));
    }

    save():void {
        this!['$v'].$touch()
        if (!this!['$v'].$invalid && this.$data.canContinue) {

            CustomerIngredientsService.create({
                'name': this.$data.model.name,
                'serving_size_desc_1': this.$data.model.serving_size_desc_1,
                'serving_size_weight_1_g': this.$data.model.serving_size_weight_1_g,
                'calories': this.$data.model.calories,
                'calories_kj': this.$data.model.calories_kj,
                'fat_g': this.$data.model.fat_g,
                'saturaded_fat_g': this.$data.model.saturaded_fat_g,
                'trans_fat_mg': this.$data.model.trans_fat_mg,
                'mono_fat_g': this.$data.model.mono_fat_g,
                'polii_fat_g': this.$data.model.polii_fat_g,
                'cholesterol_mg': this.$data.model.cholesterol_mg,
                'sodium_mg': this.$data.model.sodium_mg,
                'carbohydrate_g': this.$data.model.carbohydrate_g,
                'dietary_fiber_g': this.$data.model.dietary_fiber_g,
                'sugar_g': this.$data.model.sugar_g,
                'added_sugar_g': this.$data.model.added_sugar_g,
                'protein_g': this.$data.model.protein_g,
                'vitamin_a_ug': this.$data.model.vitamin_a_ug,
                'thiamine_ug': this.$data.model.thiamine_ug,
                'chromium_ug':  this.$data.model.chromium_ug,
                'riboflavin_ug': this.$data.model.riboflavin_ug,
                'pantothenic_acid_mg': this.$data.model.pantothenic_acid_mg,
                'vitamin_b6_mg': this.$data.model.vitamin_b6_mg,
                'niacin_mg': this.$data.model.niacin_mg,
                'folic_acid_ug': this.$data.model.folic_acid_ug,
                'vitamin_b12_ug': this.$data.model.vitamin_b12_ug,
                'vitamin_c_mg': this.$data.model.vitamin_c_mg,
                'vitamin_d_ug': this.$data.model.vitamin_d_ug,
                'vitamin_e_mg': this.$data.model.vitamin_e_mg,
                'vitamin_k_ug': this.$data.model.vitamin_k_ug,
                'calcium_mg': this.$data.model.calcium_mg,
                'copper_ug': this.$data.model.copper_ug,
                'fluor_mg': this.$data.model.fluor_mg,
                'phosphorus_mg': this.$data.model.phosphorus_mg,
                'iron_mg': this.$data.model.iron_mg,
                'magnesium_mg': this.$data.model.magnesium_mg,
                'selenium_ug': this.$data.model.selenium_ug,
                'iodine_ug': this.$data.model.iodine_ug,
                'zinc_mg': this.$data.model.zinc_mg,
                'potassium_mg': this.$data.model.potassium_mg,
                'manganese_mg': this.$data.model.manganese_mg,
                'starch_g': this.$data.model.starch_g
        }).then((response:GenericResponse) => {
                if(response.success == true) {
                    this.$router.push('/customer-ingredients');
                } else {
                    eventBus.$emit('makeToast', 'danger', 'Datos incorrectos', 'Por favor valida que no haya errores en los datos capturados.');
                }
                
            }).catch(error => {
                eventBus.$emit('makeToast', 'danger', 'Datos incorrectos', 'Por favor valida que no haya errores en los datos capturados.');
            });

        } else {
            eventBus.$emit('makeToast', 'danger', 'Datos incorrectos', 'Por favor valida que no haya errores en los datos capturados.');
        }
    }

    // calculateCaloriesInKJ():void {
    //     this.$data.model.calories_kj = ((this.$data.model.calories * 1) * 4.185).toFixed(3);
    // }
}
