
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DownloadPreventButton extends Vue {
    @Prop() private id!: string;
    @Prop() private title!: string;

    // handleOk() {
    //     this.$router.push('/my-account');
    // }

}
