
import { Component, Watch, Vue } from 'vue-property-decorator';
import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Steps from '@/components/recipe-detail/Steps.vue';
import Ingredients from '@/components/recipe-detail/Ingredients.vue';
import Label from '@/components/recipe-detail/Label.vue';
import Packing from '@/components/recipe-detail/Packing.vue';
import eventBus from '@/services/eventBus';
import RecipeService, { FullData, Recipe } from '@/services/recipes.service';
import { GenericResponse } from '@/services/interceptors';
import NameInput from '@/components/recipe-detail/NameInput.vue';
import Cookies from "js-cookie";
import BlockableSection from '@/components/BlockableSection.vue';

interface Tour {
  // Methods
  start(startStep?: string): void
  previousStep(): void
  nextStep(): void
  stop(): void
  skip(): void
  finish(): void
  currentStep: number

  // Computed
  isRunning: boolean
  isFirst: boolean
  isLast: boolean
  numberOfSteps: number
}

@Component({
  components: {
    Header, 
    Footer, 
    Sidebar,
    Steps,
    Ingredients,
    Packing,
    Label,
    NameInput,
    BlockableSection
  },
})
export default class RecipeDetail extends Vue {
  data() {
    return {
        step: 1,
        model: null,
        originalModel: null,
        options: {
          labels: {
            buttonSkip: 'No volver a mostrar',
            buttonPrevious: 'Anterior',
            buttonNext: 'Siguiente',
            buttonStop: 'Finalizar'
          }
        },
        callbacks: {
          onSkip: this.onSkipTour
        },
        steps: [
          {
            target: '#v-step-0', 
            header: {
              title: '¡Empecemos!',
            },
            content: `Asigna un nombre a tu formula o receta.`,
            params: {
              placement: 'top'
            }
          },
          {
            target: '#v-step-1', 
            header: {
              title: 'Busca y agrega tus ingredientes',
            },
            content: `Busca los ingredientes de tu receta, si tienes algún ingredientes especial, puedes capturar su valores.`,
            params: {
              placement: 'top'
            }
          },
          {
            target: '#v-step-2', 
            header: {
              title: 'Captura las cantidades en tu receta',
            },
            content: `Coloca las cantidades de cada ingredientes, si tienes un porcentaje de merma o desperdicio aquí puedes especificarlo.`,
            params: {
              placement: 'top'
            }
          },
          {
            target: '#v-step-3', 
            header: {
              title: 'Información de tu envase',
            },
            content: `Determina el contenido neto de tu envase así como la unidad de medida, si esta receta hace mas de un envase, especifica el numero de envases o paquetes que haces con esta receta`,
            params: {
              placement: 'top'
            }
          },
          {
            target: '#v-step-4', 
            header: {
              title: 'Formato de tabla nutrimental',
            },
            content: `Elige el formato en el que quieres ver los cálculos, te ofrecemos dos tipos de formato para incluir en tu diseño.`,
            params: {
              placement: 'top'
            }
          },
          {
            target: '#v-step-5', 
            header: {
              title: 'Información de nutrimentos criticos añadidos',
            },
            content: `Especifica si tu producto contiene alguno de esto nutrimentos añadidos, se usara para el calculo y valoración de los sellos de advertencia.`,
            params: {
              placement: 'top'
            }
          },
          {
            target: '#v-step-6', 
            header: {
              title: 'Extender declaración nutrimental',
            },
            content: `También puedes incluir esta información voluntaria: desglose de grasas, contenido de almidón y valores por porción.`,
            params: {
              placement: 'top'
            }
          },
          {
            target: '#v-step-7', 
            header: {
              title: 'Nutrimentos voluntarios que puedes declarar',
            },
            content: `Puedes incluir los valores de vitaminas y minerales, recuerda que la norma pide que solo se incluyan aquellos que tengan un valor de 5% o mas por porción.`,
            params: {
              placement: 'top'
            }
          },
          {
            target: '.v-step-8', 
            header: {
              title: 'Descarga tu etiquetado',
            },
            content: `Listo! Descarga el formato de tu Declaración nutrimental, (te ofrecemos dos opciones de formato) así como también los sellos que corresponden a tu producto.`,
            params: {
              placement: 'top'
            }
          },
          {
            target: '.v-step-9', 
            header: {
              title: 'Otros aspectos de tu receta',
            },
            content: `Por ultimo, aqui puedes cambiar de pantalla para usar las otras herramientas que te ofrecemos, como calculo de costos de tu producto y/o  el detalle de tu informacion nutrimental.`,
            params: {
              placement: 'top'
            }
          },
        ]
    };
  }

  created():void {
    this.loadModel(Number.parseInt(this.$route.params.id));
  }
  

  mounted():void {
        const disable_recipe_detail_tour = Cookies.get('disable_recipe_detail_tour');
        if(disable_recipe_detail_tour == undefined || JSON.parse(disable_recipe_detail_tour) == false) {
          setTimeout(() => {
              this.launchTour();
          }, 1500);
        }
  }

  launchTour() {
    let myTour = this.$refs['myTour'] as unknown as Tour;
    myTour.start();
  }

  loadModel(id:number):void {
    RecipeService.getOneFull(id).then((model:FullData) => {
      this.$data.model = model.label;
      this.$data.originalModel = model;

      this.$store.commit('updateRecipe', model.label);
      this.$store.commit('updateTable', model.table);
    }).catch(error => {
      this.$data.model = null;
      this.$router.push('/home');
    });
  }

  onSkipTour() {
    Cookies.set('disable_recipe_detail_tour', JSON.stringify(true));
  }
}
