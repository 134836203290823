
import { Component, Vue } from 'vue-property-decorator';
import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Info from '@/components/Info.vue';
import Step from '@/components/recipe-detail/Step.vue';

import CustomerIngredientsService, { CustomerIngredient } from '@/services/customer_ingredients.service';
import { GenericResponse } from '@/services/interceptors';
import eventBus from '@/services/eventBus';

@Component({
  components: {
    Header, 
    Footer, 
    Sidebar,
    Info, 
    Step,
  },
})
export default class CustomIngredients extends Vue {
  data() {
    return {
      fields: [
        { key: 'index', label: '#' },
        { key: 'name', label: 'Nombre' },
        { key: 'updated_at', label: 'Última actualización' },
        { key: 'update', label: '' },
        { key: 'delete', label: '' },
      ],
      items: []
    };
  }

  created():void {
    this.getItems();
  }

  getItems():void {
    CustomerIngredientsService.getAll().then((items:CustomerIngredient[]) => {
      this.$data.items = items;
    }).catch(error => {
      this.$data.items = [];
      // console.log(error);
    });
  }

  goToUpdate(index:number):void {
    if(this.$data.items[index] != undefined) {
      this.$router.push(`/customer-ingredient/${this.$data.items[index].id}`);
    }
  }

  goToDelete(index:number):void {
    if(this.$data.items[index] != undefined) {
      if(this.$data.items[index].recipes.length == 0) {
        CustomerIngredientsService.delete(this.$data.items[index].id).then((response:GenericResponse) => {
          if(response.success == true) {
            this.$data.items.splice(index, 1);
          } else {
              eventBus.$emit('makeToast', 'danger', 'Error al eliminar', 'No se pudo eliminar el registro.');
          }
          
        }).catch(error => {
          // this.$data.items = [];
          // console.log(error);
        });
      }
    }
  }
}
