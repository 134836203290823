

import { Component, Vue } from 'vue-property-decorator';

import AuthService from '@/services/auth.service'; // @ is an alias to /src
import ProductsService, { Product } from '@/services/products.service'; // @ is an alias to /src
import Header from '@/components/Header.vue'; // @ is an alias to /src
import { Validate, Validations } from 'vuelidate-property-decorators';
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators';
import { GenericResponse } from '@/services/interceptors';
import PrivacyContent from '@/components/PrivacyContent.vue'; // @ is an alias to /src
import UsageConditionsContent from '@/components/UsageConditionsContent.vue'; // @ is an alias to /src
import eventBus from '@/services/eventBus';

@Component(
  {
    components: { Header, 'privacy-content': PrivacyContent, 'usage-conditions-content': UsageConditionsContent  }
  }
)
export default class Signup extends Vue {

    data() {
        return {
          models: [],
          form: {
            local_product_id: null,
            // name: null,
            contact_name: null,
            // contact_phone: null,
            contact_email: null,
            password: null,
            password_repeat: null,
            // i_agree: true,
            // i_agree2: true,
            i_agree3: null,
          },
          signupError: false,
          signupComplete: false,
          signupErrorMessage: ''
        };
    }

    @Validations()
    validations() {
        return {
            form: {
            local_product_id: { required },
            // name: { required },
            contact_name: { required },
            // contact_phone: { required },
            contact_email: { email, required },
            password: { required },
            password_repeat: {
                required,
                passwordConfirm: sameAs('password')
            },
            // i_agree: {
            //     sameAs: sameAs( () => true )
            // },
            // i_agree2: {
            //     sameAs: sameAs( () => true )
            // },
            i_agree3: {
                sameAs: sameAs( () => true )
            }
            }
        };
    }

  created():void {
    if(this.$router.currentRoute.path == '/email-validation-error') {
        this.$data.signupErrorMessage = 'La llave de validación incorrecta. Pruebe creando una cuenta primero.';
        this.$data.signupError = true;
    }

    ProductsService.getAllPublic().then((models:Product[]) => {
        this.$data.models = models
            .sort((a:Product, b:Product) => {
                return a.price - b.price;
            })
            .map((model:Product) => {

                // {{model.recipes}}
                // <span v-if="model.recipes == 1">receta</span>
                // <span v-if="model.recipes > 1">recetas</span>

                // <span v-if="model.price == 0">&nbsp;&nbsp;(PRUEBA GRATIS)</span>
                // <span v-if="model.price > 0">&nbsp;&nbsp; ({{ model.price | currency }} pago único)</span>

                let html = `${model.recipes} <span class="font-weight-bold">recetas.</span>`;
                if(model.recipes == 1) {
                    html = `${model.recipes} <span class="font-weight-bold">receta.</span>`;
                }

                if(model.price == 0) {
                    html = `${html} &nbsp;&nbsp;&nbsp;(<span class="font-weight-bold">PRUEBA GRATIS</span>)`;
                } else {
                    let numberFormat = new Intl.NumberFormat();
                    const price = numberFormat.format(model.price)
                    html = `${html} &nbsp;&nbsp;&nbsp;(<span class="font-weight-bold">$${price} pago único</span>)`;
                }

                return {
                    value: model.id,
                    html: html
                };
            });

            if(models.length != 0) {
                this.$data.form.local_product_id = models[0].id;
            }
    }).catch(error => {
        this.$data.models = [];
    });
  }


  onSubmit() {
    this.$data.signupComplete = false;
    this.$data.signupError = false;
    this.$data.signupErrorMessage = '';

    this!['$v'].$touch()
    if (!this!['$v'].$invalid) {
      let data = {...this.$data.form};

      AuthService.signup(data).then((response:GenericResponse) => {
        if(response.success == true) {
            AuthService.login(data.contact_email, data.password).then(response => {
                this.$data.signupComplete = true;
                this.$data.form.name = null;
                this.$data.form.local_product_id = null;
                this.$data.form.contact_name = null;
                this.$data.form.contact_phone = null;
                this.$data.form.contact_email = null;
                this.$data.form.password = null;
                this.$data.form.password_repeat = null;
                this!['$v'].$reset()

                this.$data.signupError = false;

                this.$store.dispatch('setIdentity', response.data);
                const router = this.$router;
                setTimeout(function(){
                    router.push('/home');
                }, 1000);

            }).catch(error => {
                this.$data.signupError = true;
            });

        } else {
            const keys = Object.keys(response.error);
            let msg = '';
            for(let i = 0; i < keys.length; i++) {
                msg += response.error[keys[i]].join('<br />');
            }

            this.$data.signupErrorMessage = msg;
            this.$data.signupError = true;
        }
      }, (error) => {
        this.$data.signupErrorMessage = 'Error al generar la cuenta. ¿Email ya registrado?';
        this.$data.signupError = true;
      });
    } else {
      console.log({error: this!['$v']!['$errors']})
    }
  }
}
