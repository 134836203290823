
import { Component, Prop, Vue } from 'vue-property-decorator';
import Step from '@/components/recipe-detail/Step.vue';

@Component({
  components: {
    Step, 
  },
})
export default class Steps extends Vue {
  @Prop() private current!: number;
}
