import { render, staticRenderFns } from "./ExtendOtherNutrientsThiamine.vue?vue&type=template&id=5f3500bb&scoped=true"
import script from "./ExtendOtherNutrientsThiamine.vue?vue&type=script&lang=ts"
export * from "./ExtendOtherNutrientsThiamine.vue?vue&type=script&lang=ts"
import style0 from "./ExtendOtherNutrientsThiamine.vue?vue&type=style&index=0&id=5f3500bb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f3500bb",
  null
  
)

export default component.exports