
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import RecipeService, { FullData, Recipe } from '@/services/recipes.service';

@Component
export default class ExtendOtherNutrientsCalcium extends Vue {
  @Prop() private model!: Recipe;

  updateValue() {
    const value = this.model.extend_other_nutrients_calcium;
    RecipeService.updateOne(this.model.id, {
        extend_other_nutrients_calcium: value,
      }).then((data:FullData) => {
            this.$store.commit('updateRecipe', data.label);
            this.$store.commit('updateTable', data.table);
      }).catch(error => {
        console.log(error);
      });
  }
}
