
import { Component, Prop, Vue } from 'vue-property-decorator';
import RecipeService, { Recipe } from '@/services/recipes.service';

@Component
export default class Step extends Vue {
  @Prop() private current!: number;
  @Prop() private expanded!: boolean;
  @Prop() private enabled!: boolean;

    data() {
        return {
            items: []
        };
    }

    get isAuthenticated() {
        return this.$store.getters.isAuhtenticated;
    }

    get identity() {
        return this.$store.getters.getIdentity;
    }

    mounted():void {
        this.getItems();
    }

    getItems():void {
        RecipeService.getAll().then((items:Recipe[]) => {
            this.$data.items = items;
        }).catch(error => {
            this.$data.items = [];
        });
    }


    start():void {
        RecipeService.create().then((recipe:Recipe) => {
            this.$router.push(`recipe/${recipe.id}`);
        }).catch(error => {
            // console.log(error);
        });
    }

    getAssetPath(image:string):string {
        let images = require.context('../../assets/', true, /\.(gif|jpg|png|svg)$/);
        return images(image);

    }
}
