
import { Component, Prop, Vue } from 'vue-property-decorator';
import AccountLinks from '@/components/AccountLinks.vue';

@Component({
  components: {
    AccountLinks
  },
})
export default class Header extends Vue {
    @Prop() private title!: string;

    get showValidateWarning() {
        return false; //this.identity && this.identity.account.status == 9;
    }
    

    get showExpiredWarning() {
        return this.identity && this.identity.account.expired == true;
    }

    get isAuthenticated() {
        return this.$store.getters.isAuhtenticated;
    }

    get identity() {
        return this.$store.getters.getIdentity;
    }
}
