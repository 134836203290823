
import { Component, Prop, Vue } from 'vue-property-decorator';
import AccountOrderService, { AccountOrder } from '@/services/account_orders.service';

@Component
export default class AccountOrders extends Vue {

    data() {
        return {
            items: [],
            fields: [
                { key: 'index', label: '#' },
                { key: 'created_at', label: 'Creada' },
                { key: 'status', label: 'Estatus' },
                { key: 'qty', label: 'Recetas' },
                { key: 'total', label: 'Total' },
                { key: 'id', label: 'Acciones' },
            ],
            baseURL: process.env.VUE_APP_API_URL
        };
    }

    get identity() {
        return this.$store.getters.getIdentity;
    }

    mounted() {
        this.getItems();
    }

    getItems():void {
        AccountOrderService.getAll().then((items:AccountOrder[]) => {
            this.$data.items = items;
        }).catch(error => {
            this.$data.items = [];
        });
    }

    payOrder(id:number):void {
        window.location.href = `${this.$data.baseURL}/v1/account-order/pay-order?access-token=${this.identity.access_token}&id=${id}`;
    }


    requestInvoice(id:number):void {
        AccountOrderService.requestInvoice(id).then(() => this.getItems());
    }

    cancelOrder(id:number):void {
        //this.$router.push(`/recipe-cost/${id}`);
    }


}
