import axios from "axios";
import { GenericResponse } from "./interceptors";
import { Recipe } from "./recipes.service";

export interface CustomerIngredient {
    id:                       number;
    status:                   number;
    user_id:                  number;
    type:                     number;
    subtype:                  number;
    verified:                 number;
    is_public:                number;
    name:                     string;
    manufacturer:             null;
    ingredient_list:          null;
    serving_size_desc_1:      string;
    serving_size_weight_1_g:  number;
    serving_size_desc_2:      null;
    serving_size_weight_2_g:  number;
    serving_size_desc_3:      null;
    serving_size_weight_3_g:  number;
    serving_size_desc_4:      null;
    serving_size_weight_4_g:  number;
    serving_size_desc_5:      null;
    serving_size_weight_5_g:  number;
    serving_size_desc_6:      null;
    serving_size_weight_6_g:  number;
    serving_size_desc_7:      null;
    serving_size_weight_7_g:  number;
    serving_size_desc_8:      null;
    serving_size_weight_8_g:  number;
    serving_size_desc_9:      null;
    serving_size_weight_9_g:  number;
    serving_size_desc_10:     null;
    serving_size_weight_1_g0: number;
    data_source_url:          null;
    picture_url:              null;
    calories:                 number;
    fat_g:                    number;
    saturaded_fat_g:          number;
    trans_fat_mg:             number;
    cholesterol_mg:           number;
    sodium_mg:                number;
    carbohydrate_g:           number;
    dietary_fiber_g:          number;
    sugar_g:                  number;
    added_sugar_g:            number;
    protein_g:                number;
    vitamin_a_ug:             number;
    vitamin_b1_ug:            number;
    vitamin_b2_ug:            number;
    niacin_mg:                number;
    folic_acid_ug:            number;
    vitamin_b12_ug:           number;
    vitamin_d_ug:             number;
    vitamin_k_ug:             number;
    pantothenic_acid_mg:      number;
    calcium_mg:               number;
    chromium_ug:              number;
    fluor_mg:                 number;
    phosphorus_mg:            number;
    iron_mg:                  number;
    magnesium_mg:             number;
    selenium_ug:              number;
    iodine_ug:                number;
    created_at:               number;
    updated_at:               number;
    account_id:               number;
    calories_kj:              number;
    mono_fat_g:               number;
    polii_fat_g:              number;
    zinc_mg:                  number;
    starch_g:                 number;
    copper_ug:                number;
    manganese_mg:             number;
    potassium_mg:             number;
    riboflavin_ug:            number;
    thiamine_ug:              number;
    vitamin_c_mg:             number;
    vitamin_e_mg:             number;
    vitamin_b6_mg:            number;
    recipes:                  Recipe[];
}


class CustomerIngredientsService {
    getAll():Promise<CustomerIngredient[]> {
        return axios.get('/v1/customer-ingredient');
    }

    getOne(id:number):Promise<CustomerIngredient> {
        return axios.get(`/v1/customer-ingredient/view?id=${id}`);
    }

    delete(id:number):Promise<GenericResponse> {
        return axios.delete(`/v1/customer-ingredient/delete-ingredient?id=${id}`);
    }

    create(data:any):Promise<GenericResponse> {
        return axios.post(`/v1/customer-ingredient/create-ingredient`, data);
    }

    updateOne(id:number, data:any):Promise<GenericResponse> {
        return axios.put(`/v1/customer-ingredient/update-ingredient?id=${id}`, data);
    }
}

export default new CustomerIngredientsService();
