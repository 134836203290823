
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import HelpButton from '@/components/HelpButton.vue';
import NetWeightPerPackageInput from '@/components/recipe-detail/packings/NetWeightPerPackageInput.vue';
import PackagesInput from '@/components/recipe-detail/packings/PackagesInput.vue';
import NetWeightPerPackageUnitInput from '@/components/recipe-detail/packings/NetWeightPerPackageUnitInput.vue';
import BlockableSection from '@/components/BlockableSection.vue';

@Component({
  components: { HelpButton, NetWeightPerPackageInput, PackagesInput, NetWeightPerPackageUnitInput, BlockableSection }
})
export default class Packing extends Vue {

  data() {
    return {
      model: null,
      unitOptions: [
        { value: 'gram', text: 'gramos' },
        { value: 'ml', text: 'mililitros' },
      ]
    };
  }

  isRecipeValid():boolean {
      return this.$store.state.recipe != null &&
                    this.$store.state.recipe.grams == ( this.$store.state.recipe.net_weight_per_package * this.$store.state.recipe.packages );
  }

}
