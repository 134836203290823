
import { Component, Vue } from 'vue-property-decorator';
import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Info from '@/components/Info.vue';
import Profile from '@/components/my-account/Profile.vue';
import Recipes from '@/components/my-account/Recipes.vue';

@Component({
  components: {
    Header, 
    Footer, 
    Sidebar,
    Info, 
    Profile,
    Recipes
  },
})
export default class MyAccount extends Vue {
  data() {
    return {
    };
  }


  mounted() {
    const identity = this.$store.getters.getIdentity;
    if(identity.role != 'MANAGER') {
      this.$router.replace('/home');
    }
  }

  get identity() {
      return this.$store.getters.getIdentity;
  }
}
