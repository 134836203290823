
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import RecipeService, { FullData, Ingredient, Recipe } from '@/services/recipes.service';
import { GenericResponse } from '@/services/interceptors';
import eventBus from '@/services/eventBus';

@Component
export default class SugarAdded extends Vue {
  @Prop() private model!: Ingredient;

  updateValue() {
      const value = this.model.quantity;
      RecipeService.updateIngredient(this.model.recipe_id, this.model.id, {
        mx_sugar_added: this.model.mx_sugar_added,
      }).then((data:FullData) => {
            this.$store.commit('updateRecipe', data.label);
            this.$store.commit('updateTable', data.table);
      }).catch(error => {
        console.log(error);
      });
  }

  isDisabled() {
    // Si el ingrediente tiene azucares añadidos se considera azucares añadidos por default.
    //      Excepciones: Si se desmarca el ingrediente ya no debe considerar el azucar añadido en la tabla debe considerarlo en azucar total.

    // Quitar regla de marcar como azúcar añadido si hay mas de un ingrediente y el que esta en cuestión tiene azúcar.
    // Si el ingrediente tiene azúcar natural (sin azucares añadidos) poder seleccionar la casilla a voluntad. Y considerar el azúcar natural en los cálculos de añadidos.
    // Si el ingrediente no tiene azucares de ningún tipo, la casilla a desactivada y deseleccionada.
    let disable = this.model.usagePercent <= 0 || this.model.base100Sugar <= 0;

    // let ingredients_count = this.$store.getters.countIngredients;
    // ingredients_count = ingredients_count > 0 ? ingredients_count - 1 : 0;

    // if(!disable) {
    //   if(this.model.ingredient_type == 'custom' 
    //       && this.model.customerIngredient != null 
    //       && this.model.customerIngredient != undefined) {

    //       // Save only if forced by condition sugar added
    //       if( (this.model.customerIngredient.added_sugar_g > 0) 
    //             || (ingredients_count && this.model.customerIngredient.sugar_g > 0) ) {
    //           disable = true;
    //       }
    //   }

    //   if(this.model.ingredient_type == 'certified' 
    //       && this.model.ingredient != null 
    //       && this.model.ingredient != undefined) {
          
    //       // Save only if forced by condition sugar added
    //       if( (this.model.ingredient.nutrients.sugar_added != null && this.model.ingredient.nutrients.sugar_added > 0) 
    //             || (ingredients_count && this.model.ingredient.nutrients.sugar != null && this.model.ingredient.nutrients.sugar > 0) ) {
    //         disable = true;
    //       }
    //   }
    // }

    return disable;
  }
}
