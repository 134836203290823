
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AccountLinks extends Vue {
    data() {
        return {
        };
    }

    get isAuthenticated() {
        return this.$store.getters.isAuhtenticated;
    }

    get identity() {
        return this.$store.getters.getIdentity;
    }

    logout():void {
        this.$store.dispatch('setIdentity', null);
        this.$router.replace('/');
    }
}
