
import { Table } from '@/services/recipes.service';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class SealsLabel extends Vue {
  @Prop() private table!:Table;
  @Prop() private show!:string;
  

  data():any {
    return {
    };
  }

  showSugarAdvertisement():boolean {
    if(this.showLabel() // if recipe has seals
        && this.$store.state.recipe.ingredients.length == 1 // if recipe has one ingredient only
        && this.table != null
        && this.table.sealsPreviewUrl) {
      
      // Get file name only
      const filename = this.table.sealsPreviewUrl.match(/([^/]+)(?=\.\w+$)/);
      if(filename != null) {
        return filename[0]
                  .split('_')
                  .indexOf('2') != -1; // 2 is the sugar seal identifier
      }

      return false;
    }

    return false;
  }

  showLabel():boolean {
      return this.table != null && this.show == 'label' && this.table.sealsPreviewUrl != null;
  }

  showText():boolean {
      return this.table != null && this.show == 'text' && this.table.sealsPreviewUrl == null;
  }
}
