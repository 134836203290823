
import { Table } from '@/services/recipes.service';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class DownloadOptions extends Vue {
  @Prop() private table!:Table;

  data():any {
    return {
    };
  }
}
