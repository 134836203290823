import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import auth, { Identity } from './auth.service';
import eventBus from './eventBus';

export interface GenericResponse {
    success:           boolean;
    data:              any;
    error:             any;
}

axios.interceptors.request.use((request:AxiosRequestConfig) => {

    request.baseURL = process.env.VUE_APP_API_URL;

    // Add auth headers when no login request
    if(!request!.url!.includes('login')
        && !request!.url!.includes('reset-password')
        && !request!.url!.includes('request-password-reset')) {
        const identity:Identity|null = auth.getIdentity();
        
        if(identity != null) {
            if(request.params != undefined) {
                request.params['access-token'] = identity.access_token;
            } else {
                request.params = { 'access-token': identity.access_token };
            }
        }
    }

    eventBus.$emit('loading', true);
    return request;
}, function(err) {
    return Promise.reject(err);
});


axios.interceptors.response.use((successRes:AxiosResponse) => {
        eventBus.$emit('loading', false);
        return successRes!.data;
    }, 
    function(error) {
        eventBus.$emit('loading', false);

        if( error!.config!.url == '/v1/is-logged-in' ) {
            window.location.href = '/';
        }
        
        return Promise.reject(error);
    }
);