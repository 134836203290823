
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BlockableSection extends Vue {
    @Prop() private id!: string;
    @Prop() private title!: string;

    get downloadRateLimitExceeded():boolean {
        return this.$store.state.table.download_count > 3;
    }

    get isManager():boolean {
        return this.identity.role == 'MANAGER';
    }

    get identity() {
        return this.$store.getters.getIdentity;
    }

    handleClick() {
        if(this.downloadRateLimitExceeded) {
            this.$bvModal.show(this.id);
        }
    }

    handleOk() {
        this.$router.push('/my-account');
    }

}
