<template>
<div>
    <p><strong>Aceptaci&oacute;n </strong></p>
<p>Desarrollo e informaci&oacute;n nutrimental de M&eacute;xico proporciona este sitio web de Nutri051 (https://www.nutri051.com -el "Sitio") el cual esta sujeto al cumplimiento de los T&eacute;rminos y condiciones de uso que se listan&nbsp; a continuaci&oacute;n as&iacute; como de la Pol&iacute;tica de privacidad.</p>
<p>Al acceder y utilizar este sitio web, acepta regirse por los t&eacute;rminos y condiciones contenidos en estos documentos, si por alguna raz&oacute;n usted no acepta estar sujeto a estos t&eacute;rminos y condiciones se le solicita que no acceda ni utilice las funciones de este sitio web.</p>
<p>&nbsp;</p>
<p><strong>Modificaciones a los terminos y condiciones de uso.</strong></p>
<p>Desarrollo e información nutrimental de México se reserva el derecho de cambiar estos Términos y condiciones de uso en cualquier momento. Cuando se haga algún cambio publicaremos los Términos de uso revisados en este sitio web. 
La fecha de versión de estos Términos y condiciones de uso se indica en la parte superior de la página. Le pedimos que revise los Términos y condiciones de uso periódicamente. Su uso continuado de este sitio web después de la publicación de cambios en estos Términos y condiciones de uso significará que acepta dichos cambios. 
Le recomendamos que imprima una copia de estos Términos y condiciones de uso para sus registros.</p>
<p>&nbsp;</p>
<p><strong>Propiedad intellectual, Copyright y restricciones de uso</strong></p>
<p>Este sitio web y todos los servicios y productos, im&aacute;genes y materiales y otros componentes del sitio web contienen derechos de autor y/o est&aacute;n protegidos por registro de propiedad intelectual de Desarrollo e informaci&oacute;n nutrimental de M&eacute;xico o de terceros. No se permite copiar, volver a publicar o descargar de ninguna manera el contenido de este sitio web sin el permiso por escrito de Desarrollo e informaci&oacute;n nutrimental de M&eacute;xico. Cualquier uso no autorizado de los contenidos de este sitio web est&aacute; prohibido por la ley.</p>
<p>&nbsp;</p>
<p>Desarrollo e informaci&oacute;n nutrimental de M&eacute;xico reconoce y acepta que todos los datos ingresados por el usuario son propiedad &uacute;nica y exclusiva de el mismo, el sitio&nbsp; no compartir&aacute; sus formulaciones con nadie. &nbsp;Por datos ingresados por el usuario se entiende los nombres de recetas,&nbsp; ingredientes de las mismas, proporciones o cantidad de los ingredientes, costos de dichos ingredientes.</p>
<p>&nbsp;</p>
<p><strong>Renuncia a uso ilegal o prohibido</strong></p>
<p>Como condici&oacute;n para el uso de este sitio web, usted acepta que no utilizar&aacute; este sitio web para ning&uacute;n prop&oacute;sito que sea ilegal o est&eacute; prohibido &nbsp;por ley o por estos T&eacute;rminos&nbsp; y condiciones de uso.</p>
<p>&nbsp;</p>
<p><strong>Seguridad</strong></p>
<p>El sitio cuenta con un certificado SSL de 256 Byts para mantener la comunicaci&oacute;n encriptada entre sus equipos y el servidor y mantener segura su informacion, &nbsp;La informaci&oacute;n sensible de acceso se encuentra encriptada dentro de la base de datos del sistema, para evitar el robo de identidad en su cuenta.</p>
<p>La informaci&oacute;n para pagos como tarjetas de cr&eacute;dito y d&eacute;bito NO se almacena en nuestro sistema, se env&iacute;a directamente a la pasarela de pagos que cumple con la certificaci&oacute;n PCI DSS, si desea saber m&aacute;s respecto a la seguridad en la infraestructura de cobro, puede consultar a detalle en el sitio oficial: https://stripe.com/docs/security/stripe</p>
<p>&nbsp;</p>
<p><strong>Limitaci&oacute;n de responsabilidad</strong></p>
<p>En ning&uacute;n caso &nbsp;y bajo ninguna circunstancia Desarrollo e informaci&oacute;n nutrimental de M&eacute;xico, sus directivos, &nbsp;socios, empleados o sus afiliados ser&aacute;n responsables de ning&uacute;n da&ntilde;o indirecto, incidental, especial, punitivo o da&ntilde;o consecuente de cualquier tipo, o cualquier da&ntilde;o que surja de o est&eacute; relacionado con su uso del Sitio, el contenido y cualquier otra informaci&oacute;n obtenida en el mismo.</p>
<p>&nbsp;</p>
<p>El usuario acepta que el contenido de este sitio web se proporciona "tal cual" y "seg&uacute;n est&eacute; disponible" y sin garant&iacute;as de ning&uacute;n tipo, ya sea expresa o impl&iacute;cita, de igual forma Desarrollo e informaci&oacute;n nutrimental de M&eacute;xico&nbsp; no ser&aacute; responsable ni garantiza que el contenido o software al que pueda accederse a trav&eacute;s del SITIO se encuentre libre de errores, software malicioso o que pueda causar alg&uacute;n da&ntilde;o a nivel de software o hardware en el equipo a trav&eacute;s del cual el usuario accede al Sitio. Desarrollo e informaci&oacute;n nutrimental de M&eacute;xico &nbsp;tampoco se hace responsable de los da&ntilde;os que pudiesen ocasionarse por un uso inadecuado del sitio web. En ning&uacute;n caso Desarrollo e informaci&oacute;n nutrimental de M&eacute;xico ser&aacute; responsable por las perdidas, da&ntilde;os, multas, perjuicios de cualquier tipo que surjan por el acceso o utilizaci&oacute;n del Sitio.</p>
<p>&nbsp;</p>
<p><strong>Otros sitios de internet o enlaces en nuestro sitio</strong></p>
<p>Este sitio web puede contener enlaces a sitios de terceros. Desarrollo e informaci&oacute;n nutrimental de M&eacute;xico no asume responsabilidad por el contenido de ning&uacute;n sitio de terceros y la inclusi&oacute;n de un enlace a dicho sitio no debe ser considerada como un respaldo por parte de Desarrollo e informaci&oacute;n nutrimental de M&eacute;xico del sitio o de cualquier servicio o producto ofrecido por el propietario del sitio. Cada sitio se rige por sus propios t&eacute;rminos de uso y el uso de dicho sitio es bajo su propio riesgo. Desarrollo e informaci&oacute;n nutrimental de M&eacute;xico no asume ninguna responsabilidad por cualquier p&eacute;rdida o da&ntilde;o que surja de su uso de dicho sitio.</p>
<p><em>&nbsp;</em></p>
<p><strong>Politica de privacidad</strong></p>
<p>Desarrollo e informaci&oacute;n nutrimental de M&eacute;xico recopila y usa su informaci&oacute;n personal de acuerdo con su Pol&iacute;tica de Privacidad que forma parte de estos T&eacute;rminos y condiciones &nbsp;de uso. Revise cuidadosamente &nbsp;esta pol&iacute;tica.</p>
<p>&nbsp;</p>
<p><strong>Terminaci&oacute;n del derecho de </strong><strong>uso</strong></p>
<p>Desarrollo e informaci&oacute;n nutrimental de M&eacute;xico &nbsp;se reserve el derecho de terminaci&oacute;n del derecho de uso cuando el usuario infrinja los t&eacute;rminos y condiciones de uso, as&iacute; cuando use el sitio para un fin inadecuado.</p>
<p>Desarrollo e informaci&oacute;n nutrimental de M&eacute;xico &nbsp;puede, a su exclusivo criterio, cancelar o rescindir su derecho a utilizar este sitio web, o cualquier parte de este sitio web, en cualquier momento sin previo aviso. En caso de rescisi&oacute;n, ya no estar&aacute; autorizado a acceder a este sitio web. Desarrollo e informaci&oacute;n nutrimental de M&eacute;xico &nbsp;no es responsable ante ninguna de las partes por los da&ntilde;os que surjan de dicha terminaci&oacute;n.</p>
<p>&nbsp;</p>
<p><strong>Ley aplicable y jurisdicci&oacute;n</strong></p>
<p>Este Acuerdo se rige y debe interpretarse de acuerdo con las leyes del estado de Jalisco, M&eacute;xico y las leyes de los Estados Unidos Mexicanos aplicables en Jalisco.</p>
</div>
</template>

<script>
export default {
    name: 'UsageConditionsContent',

    data() {
        return {
            
        };
    }
};
</script>

<style lang="sass" scoped>

</style>