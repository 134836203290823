
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import RecipeCostService, { IngredientCost, RecipeCost } from '@/services/recipes_cost.service';
import { Validate, Validations } from 'vuelidate-property-decorators';
import { required, minValue } from 'vuelidate/lib/validators';
import eventBus from '@/services/eventBus';
import { GenericResponse } from '@/services/interceptors';

@Component
export default class DistributorMarginPercent extends Vue {
  @Prop() private model!: RecipeCost;


  @Validations()
  validations() {
      return {
          model: {
            distributor_margin_percent: { required, minValue: minValue(0) }
          }
      };
  }

  updateValue() {
    this!['$v'].$touch()
    if (!this!['$v'].$invalid) {

      RecipeCostService.updateOne(this.model.id, {
          distributor_margin_percent: this.model.distributor_margin_percent,
        }).then((response:GenericResponse) => {
          // this.model = model;
          // this.$data.ingredients = model.ingredients;
          // console.log(response);
            if(response.success) {
                this.$store.commit('updateRecipeCost', response.data);
            } else {
                eventBus.$emit('makeToast', 'danger', 'Error al actualizar.');
            }
        }).catch(error => {
          // console.log(error);
        });

    } else {
      eventBus.$emit('makeToast', 'danger', 'Cantidad inválida', 'Debe ingresar una cantidad mayor o igual a cero.');
    }
  }
}
