
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import RecipeService, { FullData, Recipe } from '@/services/recipes.service';

@Component
export default class HasIngredientWithSugarAdded extends Vue {
  @Prop() private model!: Recipe;

  // updateValue() {
  //   const value = this.model.hasIngredientWithSugarAdded;
  //   RecipeService.updateOne(this.model.id, {
  //       hasIngredientWithSugarAdded: value,
  //     }).then((data:FullData) => {
  //           this.$store.commit('updateRecipe', data.label);
  //           this.$store.commit('updateTable', data.table);
  //     }).catch(error => {
  //       console.log(error);
  //     });
  // }
}
