
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProductService, { Product } from '@/services/products.service';
import RecipeService, { Recipe } from '@/services/recipes.service';

@Component
export default class AccountOrders extends Vue {

    data() {
        return {
            recipes: [],
            items: [],
            fields: [
                { key: 'index', label: '#' },
                { key: 'created_at', label: 'Creada' },
                { key: 'status', label: 'Estatus' },
                { key: 'qty', label: 'Recetas' },
                { key: 'total', label: 'Total' },
            ],
            baseURL: process.env.VUE_APP_API_URL
        };
    }

    get isAuthenticated() {
        return this.$store.getters.isAuhtenticated;
    }

    get identity() {
        return this.$store.getters.getIdentity;
    }

    mounted() {
        this.getRecipes();
        this.getItems();
    }


    getRecipes():void {
        RecipeService.getAll().then((items:Recipe[]) => {
            this.$data.recipes = items;
        }).catch(error => {
            this.$data.recipes = [];
        });
    }

    getItems():void {
        ProductService.getAll().then((items:Product[]) => {
            this.$data.items = items;
        }).catch(error => {
            this.$data.items = [];
        });
    }

    createOrder(id:number):void {
        window.location.href = `${this.$data.baseURL}/v1/account-order/create-order?access-token=${this.identity.access_token}&id=${id}`;
    }

}
