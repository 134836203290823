
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import RecipeService, { FullData, Recipe } from '@/services/recipes.service';
import { Validate, Validations } from 'vuelidate-property-decorators';
import { required, minValue } from 'vuelidate/lib/validators';
import eventBus from '@/services/eventBus';

@Component
export default class PackagesInput extends Vue {
  @Prop() private model!: Recipe;

  @Validations()
  validations() {
      return {
          model: {
            packages: { required, minValue: minValue(0) }
          }
      };
  }


  updateValue() {
    this!['$v'].$touch()
    if (!this!['$v'].$invalid) {

    const value = this.model.packages; //parseInt(this.model.packages);
    RecipeService.updateOne(this.model.id, {
        packages: value,
      }).then((data:FullData) => {
            this.$store.commit('updateRecipe', data.label);
            this.$store.commit('updateTable', data.table);
      }).catch(error => {
          eventBus.$emit('makeToast', 'danger', 'Error al actualizar receta', 'Error desconocido');
        });
    } else {
      eventBus.$emit('makeToast', 'danger', 'Cantidad inválida', 'Debe ingresar una cantidad mayor o igual a cero.');
    }
  }
}
