
import AuthService, { Identity, User } from '@/services/auth.service';
import { Component, Vue } from 'vue-property-decorator';
import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Info from '@/components/Info.vue';
import { GenericResponse } from '@/services/interceptors';
import eventBus from '@/services/eventBus';
import UsersTable from '@/components/my-users/UsersTable.vue';

@Component({
  components: {
    Header, 
    Footer, 
    Sidebar,
    Info,
    UsersTable
  },
})
export default class MyUsers extends Vue {
  data() {
    return {
    };
  }

  mounted() {
    console.log(this.identity.role);
    if(this.identity.role != 'MANAGER') {
      this.$router.replace('/home');
    }
  }

  get identity() {
      return this.$store.getters.getIdentity;
  }
}
