<template>
    <div>
        <p><span><strong><span>Se presenta este aviso en cumplimiento de la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares (en adelante &ldquo;la Ley&rdquo;).</span></strong></span></p>

        <p><span><strong><span>As&iacute; mismo nos apegamos al convenio 108 del reglamento de Protecci&oacute;n de Datos Personales de la Uni&oacute;n Europea, cumpliendo con los principios fundamentales,&nbsp; de dicho reglamento de forma l&iacute;cita y transparente, fines espec&iacute;ficos, solo deben recopilarse y tratarse los datos personales que sean necesarios para cumplir esa finalidad, compatibles, no se conservan m&aacute;s tiempo del necesario y existen garant&iacute;as t&eacute;cnicas y organizativas que garantizan la seguridad de los datos personales. (Todo esto dentro de los mecanismos, procedimientos&nbsp; y candados jur&iacute;dicos administrativos establecidos para mayor seguridad y tratamiento de los datos personales dentro de la empresa).</span></strong></span></p>

        <p><span><strong><span>1. Identidad y domicilio del responsable</span></strong></span></p>

        <p><span><span>DESARROLLO E INFORMACION NUTRIMENTAL DE MEXICO (En adelante &ldquo;Nutri051&rdquo;) con domicilio para recibir notificaciones en Isla Trinidad No. 2652.&nbsp; Colonia Jardines de la Cruz, Guadalajara. Jalisco, C.P. 44950, es responsable del uso y protecci&oacute;n de los datos personales que trata y al respecto le informamos:</span></span></p>

        <p><span><strong><span>2. Finalidades del tratamiento</span></strong></span></p>

        <p><span><span>Sus datos personales podr&aacute;n ser tratados para las siguientes finalidades primarias:</span></span></p>

        <p><span><span>A. Clientes: facturar la prestaci&oacute;n de servicios de c&aacute;lculos nutrimentales. </span></span></p>

        <p><span><span>Sus datos personales podr&aacute;n ser tratados para las siguientes finalidades secundarias o accesorias.</span></span></p>

        <p><span><span>Clientes:</span></span></p>

        <p><span><span>1.- Envio de informaci&oacute;n y publicidad nutrimental.</span></span></p>

        <p><span><span>2.- Env&iacute;o de actualizaciones de la industria alimenticia.</span></span></p>

        <p><span><span>3.- Env&iacute;o de informaci&oacute;n sobre cursos y/o capacitaciones.</span></span></p>

        <p><span><strong><span>3. Mecanismos para negativa de finalidades secundarias o accesorias</span></strong></span></p>

        <p><span><span>Los clientes podr&aacute;n manifestar su negativa al tratamiento de&nbsp; datos personales para finalidades secundarias se&ntilde;aladas, al: </span></span></p>

        <p><span><span>Se&ntilde;alar en el recuadro su oposici&oacute;n a las finalidades secundarias de recibir cualquier tipo de informaci&oacute;n.</span></span></p>

        <p><span><span>La negativa del uso de sus datos personales para estas finalidades, no ser&aacute; motivo para negarle los servicios que solicita o contrata con nosotros.</span></span></p>

        <p><span><strong><span>4. Datos personales tratados</span></strong></span></p>

        <p><span><span>Para las finalidades mencionadas podemos recabar sus datos personales por:</span></span></p>

        <p><span><span>Tel&eacute;fono, correo electr&oacute;nico y p&aacute;gina web.</span></span></p>

        <p><span><span>Los datos personales recabados para las finalidades primarias son en el caso de:</span></span></p>

        <p><span><span>De los Clientes.</span></span></p>

        <p><span><span>Datos de identificaci&oacute;n y contacto: Nombre (Apellido paterno, apellido materno y nombres), Domicilio (calle, n&uacute;mero, colonia, ciudad, estado, pa&iacute;s, c&oacute;digo postal), tel&eacute;fono, celular, correo electr&oacute;nico y compa&ntilde;&iacute;a.</span></span></p>

        <p><span><span>Datos de facturaci&oacute;n: Raz&oacute;n social, direcci&oacute;n, ciudad, RFC, correo electr&oacute;nico, tel&eacute;fono..</span></span></p>

        <p><span><strong><span>5. Datos personales sensibles tratados </span></strong></span></p>

        <p><span><span>Nutri051 no realiza el tratamiento de datos sensibles.</span></span></p>

        <p><span><strong><span>6. Transferencia de datos personales</span></strong></span></p>

        <p><span><span>Nutri051 no realiza transferencias de datos personales de clientes.</span></span></p>

        <p><span><strong><span>7. Cl&aacute;usula en la que se acepta o no la transferencia de datos personales</span></strong></span></p>

        <p><span><span>No existe cl&aacute;usula de negativa de transferencia de datos personales, ya que Nutri051 solo realiza este tratamiento para el cumplimiento de obligaciones previstas por otras leyes y autoridades del Estado Mexicano.</span></span></p>

        <p><span><strong><span>8. Medios y el procedimiento para ejercer derechos ARCO</span></strong></span></p>

        <p><span><span>Para acceder, rectificar y cancelar u oponerse al tratamiento de sus datos personales, pres&eacute;ntese en nuestro domicilio con:</span></span></p>

        <p><span><span>&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Solicitud por escrito (original y copia)</span></span></p>

        <p><span><span>&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Identificaci&oacute;n oficial (original y copia)</span></span></p>

        <p><span><span>Para conocer m&aacute;s sobre los procedimientos y requisitos para el ejercicio de sus derechos ARCO, haga una cita con el C. Lic.Laura Galvez al&nbsp; correo&nbsp; <strong><span>contacto@Nutri051.mx</span></strong> encargado de la Protecci&oacute;n de Datos Personales &oacute; al tel&eacute;fono 331708 4591.</span></span></p>

        <p><span><span>Es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud de cancelaci&oacute;n de forma inmediata,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales, en dichos casos se aplicar&aacute; el bloqueo correspondiente.</span></span></p>

        <p><span><strong><span>9. Mecanismos y procedimientos para revocar el consentimiento al tratamiento de sus datos personales</span></strong></span></p>

        <p><span><span>Le informamos que para revocar el consentimiento otorgado para el tratamiento de sus datos personales, pres&eacute;ntese en Nutri051 con:</span></span></p>

        <p><span><span>a. Solicitud por escrito (original y copia)</span></span></p>

        <p><span><span>b. Identificaci&oacute;n oficial (original y copia)</span></span></p>

        <p><span><strong><span>10. Opciones y medios para limitar el uso o divulgaci&oacute;n de sus datos personales</span></strong></span></p>

        <p><span><span>Con el objeto que usted pueda limitar el uso o divulgaci&oacute;n de su informaci&oacute;n personal, le sugerimos:</span></span></p>

        <p><span><span>&bull; Inscribirse en el Registro P&uacute;blico para Evitar Publicidad, que est&aacute; a cargo de la PROFECO, con la finalidad de evitar que sus datos personales sean utilizados para recibir publicidad o promociones de empresas de bienes o servicios. Para mayor informaci&oacute;n consulte el portal electr&oacute;nico de la PROFECO.</span></span></p>

        <p><span><strong><span>11. Uso de cookies, web beacons o tecnolog&iacute;a similar </span></strong></span></p>

        <p><span><span>Nutri051 Le informa que nuestra p&aacute;gina de internet<strong> </strong></span><a href="https://www.nutri051.com"><strong><span><span>https://www.nutri051.com</span></span></strong></a><span> utiliza cookies, web beacons o tecnolog&iacute;as similares que recaban datos personales que nos permiten consultar estad&iacute;sticas sobre ciudades, estados o pa&iacute;ses desde que se accede a la misma, tiempo de navegaci&oacute;n en nuestro sitio, secciones y p&aacute;ginas consultadas. Para limitar el uso de estas tecnolog&iacute;as consulte las preferencias de privacidad establecidas en su navegador de p&aacute;ginas WEB</span></span></p>

        <p><span><strong><span>12. Procedimientos y medios por los cuales el responsable comunicar&aacute; cambios en el aviso de privacidad</span></strong></span></p>

        <p><span><span>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras pr&aacute;cticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</span></span></p>

        <p><span><span>Nos comprometemos informarle sobre los cambios que pueda sufrir el presente aviso de privacidad que se exhibe en nuestra p&aacute;gina </span><a href="https://www.nutri051.com"><strong><span><span>https://www.nutri051.com</span></span></strong></a> </span></p>

        <p><span><span>Este aviso se actualiz&oacute; y public&oacute; en los medios mencionados el 16 de agosto &nbsp;de 2021.</span></span></p>

    </div>
</template>

<script>
export default {
    name: 'PrivacyContent',

    data() {
        return {
            
        };
    }
};
</script>

<style lang="sass" scoped>

</style>