
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import BlockableSection from '@/components/BlockableSection.vue';

import RecipeService, { FullData, Ingredient, IngredientResult, Recipe, Table } from '@/services/recipes.service';
import { GenericResponse } from '@/services/interceptors';
import VerticalLabel from '@/components/labels/VerticalLabel.vue';
import LinearLabel from '@/components/labels/LinearLabel.vue';
import HelpButton from '@/components/HelpButton.vue';
import ExtendOtherNutrientsStarches from '@/components/recipe-detail/labels/ExtendOtherNutrientsStarches.vue';
import IncludeServing from '@/components/recipe-detail/labels/IncludeServing.vue';
import HasIngredientWithSugarAdded from '@/components/recipe-detail/labels/HasIngredientWithSugarAdded.vue';
import SodiumAdded from '@/components/recipe-detail/labels/SodiumAdded.vue';
import FatAdded from '@/components/recipe-detail/labels/FatAdded.vue';

import ExtendOtherNutrientsAll from '@/components/recipe-detail/labels/ExtendOtherNutrientsAll.vue';
import ExtendFatNutrients from '@/components/recipe-detail/labels/ExtendFatNutrients.vue';
import ExtendOtherNutrientsVitaminA from '@/components/recipe-detail/labels/ExtendOtherNutrientsVitaminA.vue';
import ExtendOtherNutrientsThiamine from '@/components/recipe-detail/labels/ExtendOtherNutrientsThiamine.vue';
import ExtendOtherNutrientsRiboflavin from '@/components/recipe-detail/labels/ExtendOtherNutrientsRiboflavin.vue';
import ExtendOtherNutrientsVitaminB6 from '@/components/recipe-detail/labels/ExtendOtherNutrientsVitaminB6.vue';
import ExtendOtherNutrientsNiacin from '@/components/recipe-detail/labels/ExtendOtherNutrientsNiacin.vue';
import ExtendOtherNutrientsFolidAcid from '@/components/recipe-detail/labels/ExtendOtherNutrientsFolidAcid.vue';
import ExtendOtherNutrientsVitaminB12 from '@/components/recipe-detail/labels/ExtendOtherNutrientsVitaminB12.vue';
import ExtendOtherNutrientsVitaminC from '@/components/recipe-detail/labels/ExtendOtherNutrientsVitaminC.vue';
import ExtendOtherNutrientsVitaminD from '@/components/recipe-detail/labels/ExtendOtherNutrientsVitaminD.vue';
import ExtendOtherNutrientsVitaminE from '@/components/recipe-detail/labels/ExtendOtherNutrientsVitaminE.vue';
import ExtendOtherNutrientsVitaminK from '@/components/recipe-detail/labels/ExtendOtherNutrientsVitaminK.vue';
import ExtendOtherNutrientsPantothenicAcid from '@/components/recipe-detail/labels/ExtendOtherNutrientsPantothenicAcid.vue';
import ExtendOtherNutrientsCalcium from '@/components/recipe-detail/labels/ExtendOtherNutrientsCalcium.vue';
import ExtendOtherNutrientsCopper from '@/components/recipe-detail/labels/ExtendOtherNutrientsCopper.vue';
import ExtendOtherNutrientsChromium from '@/components/recipe-detail/labels/ExtendOtherNutrientsChromium.vue';
import ExtendOtherNutrientsFluor from '@/components/recipe-detail/labels/ExtendOtherNutrientsFluor.vue';
import ExtendOtherNutrientsPhosphorus from '@/components/recipe-detail/labels/ExtendOtherNutrientsPhosphorus.vue';
import ExtendOtherNutrientsIron from '@/components/recipe-detail/labels/ExtendOtherNutrientsIron.vue';
import ExtendOtherNutrientsSelenium from '@/components/recipe-detail/labels/ExtendOtherNutrientsSelenium.vue';
import ExtendOtherNutrientsIodine from '@/components/recipe-detail/labels/ExtendOtherNutrientsIodine.vue';
import ExtendOtherNutrientsZinc from '@/components/recipe-detail/labels/ExtendOtherNutrientsZinc.vue';
import ExtendOtherNutrientsManganese from '@/components/recipe-detail/labels/ExtendOtherNutrientsManganese.vue';
import ExtendOtherNutrientsPotassium from '@/components/recipe-detail/labels/ExtendOtherNutrientsPotassium.vue';
import ExtendOtherNutrientsMagnesium from '@/components/recipe-detail/labels/ExtendOtherNutrientsMagnesium.vue';
import Servings from '@/components/recipe-detail/labels/Servings.vue';

@Component({
    components: { VerticalLabel, LinearLabel, HelpButton, ExtendOtherNutrientsAll, ExtendFatNutrients, ExtendOtherNutrientsStarches, IncludeServing, HasIngredientWithSugarAdded, SodiumAdded, FatAdded, ExtendOtherNutrientsVitaminA, ExtendOtherNutrientsThiamine, ExtendOtherNutrientsRiboflavin, ExtendOtherNutrientsVitaminB6, ExtendOtherNutrientsNiacin, ExtendOtherNutrientsFolidAcid, ExtendOtherNutrientsVitaminB12, ExtendOtherNutrientsVitaminC, ExtendOtherNutrientsVitaminD, ExtendOtherNutrientsVitaminE, ExtendOtherNutrientsVitaminK, ExtendOtherNutrientsPantothenicAcid, ExtendOtherNutrientsCalcium, ExtendOtherNutrientsCopper, ExtendOtherNutrientsChromium, ExtendOtherNutrientsFluor, ExtendOtherNutrientsPhosphorus, ExtendOtherNutrientsIron, ExtendOtherNutrientsMagnesium, ExtendOtherNutrientsSelenium, ExtendOtherNutrientsIodine, ExtendOtherNutrientsZinc, ExtendOtherNutrientsManganese, ExtendOtherNutrientsPotassium, Servings, BlockableSection }
})
export default class Label extends Vue {
  @Prop() private recipe!: Recipe;

  data() {
    return {
      model: null,
      table: null,
      labelType: 1
    };
  }


  isRecipeValid():boolean {
      return this.$store.state.recipe != null &&
                this.$store.state.recipe.grams == ( this.$store.state.recipe.net_weight_per_package * this.$store.state.recipe.packages );
  }
}
