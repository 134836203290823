
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import RecipeService, { FullData, Recipe } from '@/services/recipes.service';

@Component
export default class ExtendOtherNutrientsAll extends Vue {
  @Prop() private model!: Recipe;
  
    data() {
        return {
            selected: false
        }
    }

    @Watch('model', { deep: true })
    onModelChange(newVal: Recipe) {
      this.$data.selected = this.model.extend_other_nutrients_folid_acid
                            && this.model.extend_other_nutrients_calcium
                            && this.model.extend_other_nutrients_copper
                            && this.model.extend_other_nutrients_chromium
                            && this.model.extend_other_nutrients_fluor
                            && this.model.extend_other_nutrients_phosphorus
                            && this.model.extend_other_nutrients_iron
                            && this.model.extend_other_nutrients_magnesium
                            && this.model.extend_other_nutrients_manganese
                            && this.model.extend_other_nutrients_niacin
                            && this.model.extend_other_nutrients_potassium
                            && this.model.extend_other_nutrients_selenium
                            && this.model.extend_other_nutrients_vitamin_a
                            && this.model.extend_other_nutrients_pantothenic_acid
                            && this.model.extend_other_nutrients_thiamine
                            && this.model.extend_other_nutrients_riboflavin
                            && this.model.extend_other_nutrients_vitamin_b6
                            && this.model.extend_other_nutrients_vitamin_b12
                            && this.model.extend_other_nutrients_vitamin_d
                            && this.model.extend_other_nutrients_vitamin_e
                            && this.model.extend_other_nutrients_vitamin_c
                            && this.model.extend_other_nutrients_vitamin_k
                            && this.model.extend_other_nutrients_zinc
                            && this.model.extend_other_nutrients_iodine;
  }

  updateValue() {
    RecipeService.updateOne(this.model.id, {
        extend_other_nutrients_folid_acid: this.$data.selected,
        extend_other_nutrients_calcium: this.$data.selected,
        extend_other_nutrients_copper: this.$data.selected,
        extend_other_nutrients_chromium: this.$data.selected,
        extend_other_nutrients_fluor: this.$data.selected,
        extend_other_nutrients_phosphorus: this.$data.selected,
        extend_other_nutrients_iron: this.$data.selected,
        extend_other_nutrients_magnesium: this.$data.selected,
        extend_other_nutrients_manganese: this.$data.selected,
        extend_other_nutrients_niacin: this.$data.selected,
        extend_other_nutrients_potassium: this.$data.selected,
        extend_other_nutrients_selenium: this.$data.selected,
        extend_other_nutrients_vitamin_a: this.$data.selected,
        extend_other_nutrients_pantothenic_acid: this.$data.selected,
        extend_other_nutrients_thiamine: this.$data.selected,
        extend_other_nutrients_riboflavin: this.$data.selected,
        extend_other_nutrients_vitamin_b6: this.$data.selected,
        extend_other_nutrients_vitamin_b12: this.$data.selected,
        extend_other_nutrients_vitamin_d: this.$data.selected,
        extend_other_nutrients_vitamin_e: this.$data.selected,
        extend_other_nutrients_vitamin_c: this.$data.selected,
        extend_other_nutrients_vitamin_k: this.$data.selected,
        extend_other_nutrients_zinc: this.$data.selected,
        extend_other_nutrients_iodine: this.$data.selected,
      }).then((data:FullData) => {
            this.$store.commit('updateRecipe', data.label);
            this.$store.commit('updateTable', data.table);
      }).catch(error => {
        console.log(error);
      });
  }
}
