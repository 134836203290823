import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './services/interceptors.ts';
import Vuelidate from 'vuelidate'
import store from './store'

import moment from 'moment-timezone'
moment.tz.setDefault("America/Mexico_City");
moment.locale('es')

import VueTour from 'vue-tour'
import 'vue-tour/dist/vue-tour.css';

Vue.use(VueTour)

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faInstagramSquare, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFacebook)
library.add(faInstagramSquare)
library.add(faWhatsapp)

Vue.component('font-awesome-icon', FontAwesomeIcon)

// Import Bootstrap an BootstrapVue CSS files (order is important)
import './scss/bootstrap.scss';


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(Vuelidate)

Vue.config.productionTip = false


Intl.NumberFormat

Vue.filter('shortdate', function (value:number) {
  if (!value) return ''
  return moment.unix(value).calendar();
})

Vue.filter('number', function (value:number) {
  if (!value) return '0.00'
  const formatted = new Intl.NumberFormat("es-MX").format(value);
  return `${formatted}`; 
})

Vue.filter('currency', function (value:number) {
  if (!value) return '$0.00'
  const formatted = new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(value);
  return `${formatted}`; 
})

import VueGtm from '@gtm-support/vue2-gtm';
Vue.use(VueGtm, {
  id: 'GTM-MLVKZDT',
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
