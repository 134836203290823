
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import RecipeService, { FullData, IngredientResult, Recipe } from '@/services/recipes.service';
import { GenericResponse } from '@/services/interceptors';
import HelpButton from '@/components/HelpButton.vue';
import eventBus from '@/services/eventBus';
import SugarAdded from '@/components/recipe-detail/ingredients/SugarAdded.vue';
import Quantity from '@/components/recipe-detail/ingredients/Quantity.vue';
import Unit from '@/components/recipe-detail/ingredients/Unit.vue';
import WastePercent from '@/components/recipe-detail/ingredients/WastePercent.vue';
import BlockableSection from '@/components/BlockableSection.vue';

import { Validations } from 'vuelidate-property-decorators';
import { required, minLength } from 'vuelidate/lib/validators';

@Component({
  components: { HelpButton, SugarAdded, Quantity, Unit, WastePercent, BlockableSection }
})
export default class Ingredients extends Vue {
  // @Prop() private recipe!: Recipe;

  data() {
    return {
      model: null,
      fields: [
        { key: 'index', label: '#', thAttr: { width: '60' }   },
        // { key: 'type', 'label': '' },
        { key: 'name', label: 'Nombre', thAttr: { }   },
        { key: 'quantity', label: 'Cantidad', thAttr: { width: '150' }  },
        { key: 'unit', label: 'Unidad', thAttr: { width: '200' }  },
        { key: 'waste_percent', label: 'Desperdicio (%)', thAttr: { width: '200' } },
        { key: 'grams', label: 'Total', thClass: "text-right", tdClass: "text-right", thAttr: { width: '200' }  },
        { key: 'delete', label: '', thAttr: { width: '50' }  },
      ],
      query: '',
      resultFields: [
        // { key: 'type', label: '' },
        { key: 'name', label: 'Nombre' },
        { key: 'add', label: '', tdClass: "text-right" },
      ],
      results: [],
      searched: false,
    };
  }

  @Validations()
  validations() {
    return {
      query: { required, minLength: minLength(3) },
    }
  }

  totalGrams() {
    let total = 0;
    for(let i = 0; i < this.$store.state.recipe.ingredients.length; i++) {
      total += parseFloat(this.$store.state.recipe.ingredients[i].grams);
    }

    return `${total}g`;
  }

  searchIngredients():void {
      this!['$v'].$touch()
      if (!this!['$v'].$invalid) {
          this.$data.searched = true;
          RecipeService.searchIngredients(this.$data.query).then((results:IngredientResult[]) => {  
            this.$data.results = results;
          }).catch(error => {
            console.log(error);
          });
      }
  }

  getGrams(index:number) {
    if(this.$store.state.recipe.ingredients[index] != undefined) {
      const quantity = parseFloat(this.$store.state.recipe.ingredients[index].quantity);
      const waste_percent = parseFloat(this.$store.state.recipe.ingredients[index].waste_percent);

      return (((100 - waste_percent) * quantity) / 100).toFixed(2);
    }
  }

  deleteIngredient(index:number):void {
    if(this.$store.state.recipe.ingredients[index] != undefined) {
      RecipeService.deleteIngredient(this.$store.state.recipe.id, this.$store.state.recipe.ingredients[index].id)
      .then((data:FullData) => {
            this.$store.commit('updateRecipe', data.label);
            this.$store.commit('updateTable', data.table);
      }).catch(error => {
        console.log(error);
      });
    }
  }

  addIngredient(index:number):void {
      RecipeService.addIngredient(this.$store.state.recipe.id, this.$data.results[index].id, this.$data.results[index].type)
      .then((data:FullData) => {
            this.$store.commit('updateRecipe', data.label);
            this.$store.commit('updateTable', data.table);
      }).catch(error => {
        console.log(error);
      });
  }

  isSolid(unit:string):boolean {
    unit = unit.toUpperCase();
    let result = false;
    switch(unit) {
        case 'ML':
        case 'FLUID_OZ':
            result = false;
            break;
        case 'GRAM':
        case 'GRAMS':
        case 'OZ':
            result = true
            break;
    }

    return result;
  }

  toGrams(qty:number, unit:string):number {
    unit = unit.trim().toUpperCase();
    let grams = 0;
    switch(unit) {
        case 'FLUID_OZ':
            grams = qty * 29.57352956;
            break;
        case 'OZ':
            grams = qty * 28.3495;
            break;
        case 'MG':
            grams = qty / 1000.0;
            break;
        case 'UG':
            grams = qty / 1000000.0;
            break;
        case 'G':
        case 'GRAM':
        case 'ML':
            grams = qty * 1.0;
            break;
    }

    return grams;
  }


  // updateModel():void {
  //   RecipeService.getOne(this.$store.state.recipe.id).then((model:Recipe) => {
  //     // this.$data.model = model;
  //     // eventBus.$emit('recipe-model-updated', model);
  //     this.$store.commit('updateRecipe', model);
  //   }).catch(error => {
  //     // this.$data.model = null;
  //     this.$router.push('/home');
  //   });
  // }

}
