
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import RecipeCostService, { IngredientCost, RecipeCost } from '@/services/recipes_cost.service';
import { Validate, Validations } from 'vuelidate-property-decorators';
import { required, minValue } from 'vuelidate/lib/validators';
import eventBus from '@/services/eventBus';
import { GenericResponse } from '@/services/interceptors';

@Component
export default class PriceUnit extends Vue {
  @Prop() private model!: IngredientCost;


  @Validations()
  validations() {
      return {
          model: {
            price_unit: { required }
          }
      };
  }

  data():any {
    return {
      unitOptions: [
        // { value: 'cup', 'text': '1 taza' },
        // { value: 'lb', 'text': '1 libra' },
        // { value: 'gallon', 'text': '1 galón' },
        // { value: 'oz', 'text': '1 onza' },
        { value: 'kg', 'text': '1 kg' },
        { value: 'gram', 'text': '1 gramo' },
        { value: 'ml', 'text': '1 ml' },
        { value: 'l', 'text': '1 l' },
      ]
    };
  }

  updateValue() {
    this!['$v'].$touch()
    if (!this!['$v'].$invalid) {

        const value = this.model.price_unit;
        RecipeCostService.updateIngredient(this.model.recipe_cost_id, this.model.id, {
            // batchCost: this.getIngredientBatchCost(i),
            price_unit: value,
        }).then((response:GenericResponse) => {
            // this.$data.model = model;
            // this.$data.ingredients = model.ingredients;
            // console.log(response);
            if(response.success) {
                this.$store.commit('updateRecipeCost', response.data);
            } else {
                eventBus.$emit('makeToast', 'danger', 'Error al actualizar.');
            }
        }).catch(error => {
            // console.log(error);
        });

    } else {
      eventBus.$emit('makeToast', 'danger', 'Cantidad inválida', 'Debe ingresar una cantidad mayor o igual a cero.');
    }
  }
}
