import axios, { AxiosResponse } from "axios";
import { CustomerIngredient } from "./customer_ingredients.service";
import { GenericResponse } from "./interceptors";

export interface FullData {
    label: Recipe,
    table: Table
}

export interface Recipe {
    id:                                 number;
    status:                             number;
    user_id:                            number;
    name:                               string;
    reference:                          string;
    net_weight_per_package:             string;
    net_weight_per_package_unit:        string;
    packages:                           string;
    serving_description:                string;
    servings:                           string;
    waste_percent:                      string;
    grams:                              string;
    created_at:                         number;
    updated_at:                         number;
    ingredient_type:                    string;
    account_id:                         number;
    extend_fat_nutrients:               number;
    extend_other_nutrients_folid_acid:  number;
    extend_other_nutrients_starches:    number;
    extend_other_nutrients_calcium:     number;
    extend_other_nutrients_copper:      number;
    extend_other_nutrients_chromium:      number;
    extend_other_nutrients_fluor:      number;
    extend_other_nutrients_phosphorus:  number;
    extend_other_nutrients_iron:        number;
    extend_other_nutrients_magnesium:   number;
    extend_other_nutrients_manganese:   number;
    extend_other_nutrients_niacin:      number;
    extend_other_nutrients_potassium:   number;
    extend_other_nutrients_riboflavin:  number;
    extend_other_nutrients_selenium:    number;
    extend_other_nutrients_thiamine:    number;
    extend_other_nutrients_vitamin_b12: number;
    extend_other_nutrients_vitamin_a:   number;
    extend_other_nutrients_pantothenic_acid:   number;
    extend_other_nutrients_vitamin_b6:  number;
    extend_other_nutrients_vitamin_d:   number;
    extend_other_nutrients_vitamin_e:   number;
    extend_other_nutrients_vitamin_c:   number;
    extend_other_nutrients_zinc:        number;
    extend_other_nutrients_vitamin_k:   number;
    round_label_values:                 number;
    sodium_added:                       number;
    fat_added:                          number;
    include_serving:                    number;
    extend_other_nutrients_iodine:      number;
    ingredients:                        Ingredient[];
    base100EnergeticContent:   number;
    energeticContent:          number;
    base100EnergeticContentKJ: number;
    energeticContentKJ:        number;
    base100Protein:            number;
    protein:                   number;
    base100Fats:               number;
    fats:                      number;
    base100SaturedFats:        number;
    saturedFats:               number;
    base100TransFats:          number;
    transFats:                 number;
    base100PoliiFats:          number;
    poliiFats:                 number;
    base100MonoFats:           number;
    monoFats:                  number;
    base100Cholesterol:        number;
    cholesterol:               number;
    base100Carbohydrate:       number;
    carbohydrate:              number;
    base100Sugar:              number;
    sugar:                     number;
    base100SugarAdded:         number;
    sugarAdded:                number;
    base100DietaryFiber:       number;
    dietaryFiber:              number;
    base100Sodium:             number;
    sodium:                    number;
    base100Starch:             number;
    starch:                    number;
    base100FolicAcid:          number;
    folicAcid:                 number;
    base100Calcium:            number;
    calcium:                   number;
    base100Copper:             number;
    copper:                    number;
    base100Phosphorus:         number;
    phosphorus:                number;
    base100Iron:               number;
    iron:                      number;
    base100Magnesium:          number;
    magnesium:                 number;
    base100Manganese:          number;
    manganese:                 number;
    base100Niacin:             number;
    niacin:                    number;
    base100Potassium:          number;
    potassium:                 number;
    base100Riboflavina:        number;
    riboflavina:               number;
    base100Selenium:           number;
    selenium:                  number;
    base100Thiamine:           number;
    thiamine:                  number;
    base100VitaminB12:         number;
    vitaminB12:                number;
    base100VitaminA:           number;
    vitaminA:                  number;
    base100VitaminB6:          number;
    vitaminB6:                 number;
    base100VitaminC:           number;
    vitaminC:                  number;
    base100VitaminD:           number;
    vitaminD:                  number;
    base100VitaminE:           number;
    vitaminE:                  number;
    base100VitaminK:           number;
    vitaminK:                  number;
    base100Zinc:               number;
    zinc:                      number;
    base100Iodine:             number;
    iodine:                    number;

    

    hasIngredientWithSugarAdded:       boolean;
    energeticContentByRecipe: number;
    energeticContentKJByRecipe: number;
    proteinByRecipe: number;
    fatsByRecipe: number;
    saturedFatsByRecipe: number;
    transFatsByRecipe: number;
    poliiFatsByRecipe: number;
    monoFatsByRecipe: number;
    cholesterolByRecipe: number;
    carbohydrateByRecipe: number;
    sugarByRecipe: number;
    sugarAddedByRecipe: number;
    dietaryFiberByRecipe: number;
    sodiumByRecipe: number;
    starchByRecipe: number;

    calciumByRecipe:    number;
    copperByRecipe: number;
    phosphorusByRecipe: number;
    ironByRecipe:   number;
    magnesiumByRecipe:  number;
    niacinByRecipe: number;
    potassiumByRecipe:  number;
    riboflavinaByRecipe:    number;
    seleniumByRecipe:   number;
    thiamineByRecipe:   number;
    folicAcidByRecipe:  number;
    vitaminB12ByRecipe: number;
    vitaminAByRecipe:   number;
    vitaminB6ByRecipe:  number;
    vitaminCByRecipe:   number;
    vitaminDByRecipe:   number;
    vitaminEByRecipe:   number;
    vitaminKByRecipe:   number;
    zincByRecipe:   number;
    iodineByRecipe: number;
}

export interface Ingredient {
    id:                     number;
    user_id:                number;
    account_id:             number;
    recipe_id:              number;
    quantity:               number;
    unit:                   string;
    waste_percent:          number;
    grams:                  number;
    statement:              string;
    mx_sugar_added:         number;
    mx_trans_fat_added:     number;
    spicy_flavor:           string;
    customer_ingredient_id: null;
    percent:                null;
    ingredient_type:        string;
    ingredient_id:          number;
    created_at:             number;
    updated_at:             number;
    customerIngredient:     CustomerIngredient | null;
    ingredient:             CertifiedIngredient | null;
    usagePercent:              number;
    base100EnergeticContent:   number;
    energeticContent:          number;
    base100EnergeticContentKJ: number;
    energeticContentKJ:        number;
    base100Protein:            number;
    protein:                   number;
    base100Fats:               number;
    fats:                      number;
    base100SaturedFats:        number;
    saturedFats:               number;
    base100TransFats:          number;
    transFats:                 number;
    base100PoliiFats:          number;
    poliiFats:                 number;
    base100MonoFats:           number;
    monoFats:                  number;
    base100Cholesterol:        number;
    cholesterol:               number;
    base100Carbohydrate:       number;
    carbohydrate:              number;
    base100Sugar:              number;
    sugar:                     number;
    base100SugarAdded:         number;
    sugarAdded:                number;
    base100DietaryFiber:       number;
    dietaryFiber:              number;
    base100Sodium:             number;
    sodium:                    number;
    base100Starch:             number;
    starch:                    number;
    base100FolicAcid:          number;
    folicAcid:                 number;
    base100Calcium:            number;
    calcium:                   number;
    base100Copper:             number;
    copper:                    number;
    base100Phosphorus:         number;
    phosphorus:                number;
    base100Iron:               number;
    iron:                      number;
    base100Magnesium:          number;
    magnesium:                 number;
    base100Manganese:          number;
    manganese:                 number;
    base100Niacin:             number;
    niacin:                    number;
    base100Potassium:          number;
    potassium:                 number;
    base100Riboflavina:        number;
    riboflavina:               number;
    base100Selenium:           number;
    selenium:                  number;
    base100Thiamine:           number;
    thiamine:                  number;
    base100VitaminB12:         number;
    vitaminB12:                number;
    base100VitaminA:           number;
    vitaminA:                  number;
    base100VitaminB6:          number;
    vitaminB6:                 number;
    base100VitaminC:           number;
    vitaminC:                  number;
    base100VitaminD:           number;
    vitaminD:                  number;
    base100VitaminE:           number;
    vitaminE:                  number;
    base100VitaminK:           number;
    vitaminK:                  number;
    base100Zinc:               number;
    zinc:                      number;
    base100Iodine:             number;
    iodine:                    number;

    base100Chromium:            number;
    chromium:                   number;
    base100Fluor:            number;
    fluor:                   number;

    energeticContentByRecipe: number;
    energeticContentKJByRecipe: number;
    proteinByRecipe: number;
    fatsByRecipe: number;
    saturedFatsByRecipe: number;
    transFatsByRecipe: number;
    poliiFatsByRecipe: number;
    monoFatsByRecipe: number;
    cholesterolByRecipe: number;
    carbohydrateByRecipe: number;
    sugarByRecipe: number;
    sugarAddedByRecipe: number;
    dietaryFiberByRecipe: number;
    sodiumByRecipe: number;
    starchByRecipe: number;
}

export interface CertifiedIngredient {
    id:        number;
    fdc_id:    number;
    name:      string;
    nutrients: Nutrients;
}


export interface Nutrients {
    sugar:       number;
    sugar_added: number;
}

export interface Nutrient {
    id:     number;
    fdc_id: number;
    nt_id:  number;
    value:  string;
}

export interface IngredientResult {
    type: string;
    id:   number;
    name: string;
}

export interface Table {
    energeticContentByPackage: string;
    energeticContentKJByPackage: string;
    energeticContent100:    string;
    energeticContent:   string;
    energeticContent100KJ:    string;
    energeticContentKJ:   string;
    protein100: string;
    protein:    string;
    fats100:    string;
    fats:   string;
    saturedFats100: string;
    saturedFats:    string;
    poliiFats100:   string;
    poliiFats:  string;
    monoFats100:    string;
    monoFats:   string;
    transFats100:   string;
    transFats:  string;
    cholesterol100: string;
    cholesterol:    string;
    carbohydrate100:    string;
    carbohydrate:   string;
    sugar100:   string;
    sugar:  string;
    sugarAdded100:  string;
    sugarAdded: string;
    dietaryFiber100:    string;
    dietaryFiber:   string;
    sodium100:  string;
    sodium: string;
    starch100:  string;
    starch: string;
    potassium100:  string;
    potassium: string;
    manganese100:  string;
    manganese: string;
    includedPercentFolicAcid:   string;
    includedPercentCalcium: string;
    includedPercentChromium: string;
    includedPercentFluor: string;
    includedPercentCopper:  string;
    includedPercentPhosphorus:  string;
    includedPercentIron:    string;
    includedPercentMagnesium:   string;
    includedPercentManganese:   string;
    includedPercentNiacin:  string;
    includedPercentPotassium:   string;
    includedPercentRiboflavina: string;
    includedPercentSelenium:    string;
    includedPercentThiamine:    string;
    includedPercentVitaminB12:  string;
    includedPercentVitaminA:    string;
    includedPercentPantothenicAcid:    string;
    includedPercentVitaminB6:   string;
    includedPercentVitaminC:    string;
    includedPercentVitaminD:    string;
    includedPercentVitaminE:    string;
    includedPercentVitaminK:    string;
    includedPercentZinc:    string;
    sealsPreviewUrl:    string;
    sealsUrl:   string;
    downloadTableVerticalUrl: string;
    downloadTableLinearUrl: string;
    servings: string;
    servingUnit: string;
    download_count: number;
}


class RecipeService {
    getAll():Promise<Recipe[]> {
        return axios.get('/v1/recipe');
    }

    getOneFull(id:number):Promise<FullData> {
        return axios.get(`/v1/recipe/full-data?id=${id}`);
    }

    getOne(id:number):Promise<Recipe> {
        return axios.get(`/v1/recipe/view?id=${id}`);
    }

    getTable(id:number):Promise<Table> {
        return axios.get(`/v1/recipe/table?id=${id}`);
    }

    deleteIngredient(id:number, iid:number):Promise<FullData> {
        return axios.delete(`/v1/recipe/delete-ingredient?id=${id}&iid=${iid}`);
    }

    searchIngredients(query:string):Promise<IngredientResult[]> {
        return axios.post(`/v1/recipe/search-ingredient`, {
            'query': query
        });
    }

    addIngredient(recipeId:number, id:number, type:string):Promise<FullData> {
        return axios.post(`/v1/recipe/add-ingredient?id=${recipeId}`, {
            'id': id,
            'type': type,
        });
    }

    updateIngredient(recipeId:number, id:number, data:any):Promise<FullData> {
        return axios.put(`/v1/recipe/update-ingredient?id=${recipeId}&iid=${id}`, data);
    }

    updateIngredients(ingredients:Ingredient[]):Promise<GenericResponse> {

        const ingredientsUpdate = ingredients.map(
            (ingredient:Ingredient) => 
                axios.put(`/v1/recipe/update-ingredient?id=${ingredient.recipe_id}&iid=${ingredient.id}`, ingredient)
        );

        return axios.all(ingredientsUpdate).then(axios.spread((...responses) => {
            return { success: true } as GenericResponse;
        })).catch(errors => {
            return { success: false } as GenericResponse;
        })
    }

    create():Promise<Recipe> {
        return axios.post(`/v1/recipe/create-recipe`);
    }

    updateOne(id:number, data:any):Promise<FullData> {
        return axios.put(`/v1/recipe/update-recipe?id=${id}`, data);
    }
}

export default new RecipeService();
