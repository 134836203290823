
import { Component, Watch, Vue } from 'vue-property-decorator';
import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Steps from '@/components/recipe-detail/Steps.vue';

import RecipeService, { FullData, Ingredient, IngredientResult, Recipe } from '@/services/recipes.service';
import { GenericResponse } from '@/services/interceptors';

@Component({
  components: {
    Header, 
    Footer, 
    Sidebar,
    Steps,
  },
})
export default class RecipeIngredients extends Vue {
  data() {
    return {
      model: null,
      fields: [
        { key: 'index', label: '#' },
        // { key: 'type', 'label': '' },
        { key: 'name', label: 'Nombre' },
        { key: 'delete', label: '', tdClass: "text-right" },
      ],
      query: '',
      resultFields: [
        // { key: 'type', label: '' },
        { key: 'name', label: 'Nombre' },
        { key: 'add', label: '', tdClass: "text-right" },
      ],
      results: [],
      searched: false,
      canContinue: false,
    };
  }

  @Watch('model.name', { deep: false })
  onModelChange(newVal: string):void {

    RecipeService.updateOne(this.$data.model.id, {
        name: newVal
      }).then((response:FullData) => {
        // this.$data.model = model;
        // this.$data.ingredients = model.ingredients;
        // console.log(response);
      }).catch(error => {
        // console.log(error);
      });

    

  }

  @Watch('model.ingredients', { deep: false })
  onModelIngredientsChange(newVal: Ingredient[]):void {
    this.$data.canContinue = newVal.length != 0;
  }



  created():void {
    this.getModel(Number.parseInt(this.$route.params.id));
  }

  getModel(id:number):void {
    RecipeService.getOne(id).then((model:Recipe) => {
      this.$data.model = model;
    }).catch(error => {
      this.$data.model = null;
      this.$router.push('/home');
      console.log(error);
    });
  }

  deleteIngredient(index:number):void {
    if(this.$data.model.ingredients[index] != undefined) {
      RecipeService.deleteIngredient(this.$data.model.id, this.$data.model.ingredients[index].id)
      .then((response:FullData) => {
        this.$data.model.ingredients.splice(index, 1);
      }).catch(error => {
        console.log(error);
      });
    }
  }

  searchIngredients():void {
      this.$data.searched = true;
      RecipeService.searchIngredients(this.$data.query).then((results:IngredientResult[]) => {  
        this.$data.results = results;
      }).catch(error => {
        console.log(error);
      });
  }

  addIngredient(index:number):void {
      RecipeService.addIngredient(this.$data.model.id, this.$data.results[index].id, this.$data.results[index].type)
      .then((results:FullData) => {  
        this.getModel(Number.parseInt(this.$route.params.id));
      }).catch(error => {
        console.log(error);
      });
  }

  nextStep():void {
    this.$router.push(`/recipe-formula/${this.$data.model.id}`);
  }
}
