import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'
import Home from '../views/Home.vue'
import Recipe from '../views/RecipeDetail.vue'
import RecipeIngredients from '../views/RecipeIngredients.vue'
import RecipeFormula from '../views/RecipeFormula.vue'
import RecipePacking from '../views/RecipePacking.vue'
import CustomerIngredients from '../views/CustomerIngredients.vue'
import CustomerIngredientUpdate from '../views/CustomerIngredientUpdate.vue'
import CustomerIngredientCreate from '../views/CustomerIngredientCreate.vue'
import RecipeCosts from '../views/RecipeCosts.vue'
import RecipeLabels from '../views/RecipeLabels.vue'
import RecipeNutritionBreakdown from '../views/RecipeNutritionBreakdown.vue';
import MyAccount from '../views/MyAccount.vue';
import MyUsers from '../views/MyUsers.vue';
import RequestPasswordReset from '../views/RequestPasswordReset.vue';
import ResetPassword from '../views/ResetPassword.vue';
import Privacy from '../views/Privacy.vue';

import AuthService from '@/services/auth.service' // @ is an alias to /src
import { GenericResponse } from '@/services/interceptors'
import store from '@/store'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => { next(); }
  },
  {
    path: '/recuperar-constrasena',
    name: 'RequestPasswordReset',
    component: RequestPasswordReset,
    beforeEnter: (to, from, next) => { next(); }
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    beforeEnter: (to, from, next) => { next(); }
  },
  {
    path: '/email-validation-success',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => { next(); }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    beforeEnter: (to, from, next) => { next(); }
  },
  {
    path: '/email-validation-error',
    name: 'Signup',
    component: Signup,
    beforeEnter: (to, from, next) => { next(); }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    beforeEnter: async (to, from, next) => { 
      await store.dispatch('loadIdentity');
      const identity = store.getters.getIdentity;

      if(!identity) next({ name: 'Login' });
      identity.expired ? next({ name: 'MyAccount' }) : next();
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    //beforeEnter: (to, from, next) => { next(); }
    beforeEnter: async (to, from, next) => { 
      await store.dispatch('loadIdentity');
      const identity = store.getters.getIdentity;

      if(!identity) next({ name: 'Login' });
      identity.expired ? next({ name: 'MyAccount' }) : next();
    }
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: MyAccount,
    //beforeEnter: (to, from, next) => { next(); }
    beforeEnter: async (to, from, next) => { 
      await store.dispatch('loadIdentity');
      const identity = store.getters.getIdentity;

      if(!identity) next({ name: 'Login' });
      identity.expired ? next({ name: 'MyAccount' }) : next();
    }
  },
  {
    path: '/my-users',
    name: 'MyUsers',
    component: MyUsers,
    //beforeEnter: (to, from, next) => { next(); }
    beforeEnter: async (to, from, next) => { 
      await store.dispatch('loadIdentity');
      const identity = store.getters.getIdentity;

      if(!identity) next({ name: 'Login' });
      identity.expired ? next({ name: 'MyAccount' }) : next();
    }
  },
  {
    path: '/recipe/:id',
    name: 'Recipe',
    component: Recipe,
    beforeEnter: async (to, from, next) => { 
      await store.dispatch('loadIdentity');
      const identity = store.getters.getIdentity;

      if(!identity) next({ name: 'Login' });
      identity.expired ? next({ name: 'MyAccount' }) : next();
    }
  },
  {
    path: '/recipe-ingredients/:id',
    name: 'RecipeIngredients',
    component: RecipeIngredients,
    beforeEnter: async (to, from, next) => { 
      await store.dispatch('loadIdentity');
      const identity = store.getters.getIdentity;

      if(!identity) next({ name: 'Login' });
      identity.expired ? next({ name: 'MyAccount' }) : next();
    }
  },
  {
    path: '/recipe-formula/:id',
    name: 'RecipeFormula',
    component: RecipeFormula,
    beforeEnter: async (to, from, next) => { 
      await store.dispatch('loadIdentity');
      const identity = store.getters.getIdentity;

      if(!identity) next({ name: 'Login' });
      identity.expired ? next({ name: 'MyAccount' }) : next();
    }
  },
  {
    path: '/recipe-packing/:id',
    name: 'RecipePacking',
    component: RecipePacking,
    beforeEnter: async (to, from, next) => { 
      await store.dispatch('loadIdentity');
      const identity = store.getters.getIdentity;

      if(!identity) next({ name: 'Login' });
      identity.expired ? next({ name: 'MyAccount' }) : next();
    }
  },
  {
    path: '/customer-ingredients',
    name: 'CustomerIngredients',
    component: CustomerIngredients,
    beforeEnter: async (to, from, next) => { 
      await store.dispatch('loadIdentity');
      const identity = store.getters.getIdentity;

      if(!identity) next({ name: 'Login' });
      identity.expired ? next({ name: 'MyAccount' }) : next();
    }
  },
  {
    path: '/customer-ingredient',
    name: 'CustomerIngredientCreate',
    component: CustomerIngredientCreate,
    beforeEnter: async (to, from, next) => { 
      await store.dispatch('loadIdentity');
      const identity = store.getters.getIdentity;

      if(!identity) next({ name: 'Login' });
      identity.expired ? next({ name: 'MyAccount' }) : next();
    }
  },
  {
    path: '/customer-ingredient/:id',
    name: 'CustomerIngredientUpdate',
    component: CustomerIngredientUpdate,
    beforeEnter: async (to, from, next) => { 
      await store.dispatch('loadIdentity');
      const identity = store.getters.getIdentity;

      if(!identity) next({ name: 'Login' });
      identity.expired ? next({ name: 'MyAccount' }) : next();
    }
  },
  {
    path: '/recipe-cost/:id',
    name: 'RecipeCosts',
    component: RecipeCosts,
    beforeEnter: async (to, from, next) => { 
      await store.dispatch('loadIdentity');
      const identity = store.getters.getIdentity;

      if(!identity) next({ name: 'Login' });
      identity.expired ? next({ name: 'MyAccount' }) : next();
    }
  },
  {
    path: '/recipe-labels/:id',
    name: 'RecipeLabels',
    component: RecipeLabels,
    beforeEnter: async (to, from, next) => { 
      await store.dispatch('loadIdentity');
      const identity = store.getters.getIdentity;

      if(!identity) next({ name: 'Login' });
      identity.expired ? next({ name: 'MyAccount' }) : next();
    }
  },
  {
    path: '/recipe-nutrition-breakdown/:id',
    name: 'RecipeNutritionBreakdown',
    component: RecipeNutritionBreakdown,
    beforeEnter: async (to, from, next) => { 
      await store.dispatch('loadIdentity');
      const identity = store.getters.getIdentity;

      if(!identity) next({ name: 'Login' });
      identity.expired ? next({ name: 'MyAccount' }) : next();
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    beforeEnter: async (to, from, next) => { 
      await store.dispatch('loadIdentity');
      const identity = store.getters.getIdentity;

      if(!identity) next({ name: 'Login' });
      identity.expired ? next({ name: 'MyAccount' }) : next();
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
