
import { Component, Vue } from 'vue-property-decorator';
import AuthService, { Identity, User } from '@/services/auth.service';
import eventBus from '@/services/eventBus';
import { GenericResponse } from '@/services/interceptors';

@Component({
  components: {
  },
})
export default class AddUser extends Vue {
    data() {
        return {
            form: {
                status: 10,
                name: null,
                email: null,
            },
            options: [
                {
                    'value': 10,
                    'text': 'Activo',
                },
                {
                    'value': 9,
                    'text': 'Suspendido',
                }
            ]
        };
    }

    // mounted():void {

    // }

    reset() {
        this.$data.form =  {
            status: 10,
            name: null,
            email: null,
        }
    }

    handleOk() {
        AuthService.addUser(this.$data.form).then((response:GenericResponse) => {
            if(response.success) {
                this.$root.$emit('bv::hide::modal', 'modal-new-user', '#btnShow')
                eventBus.$emit('reloadUsersAccess');
                this.reset();
            } else {
                eventBus.$emit('makeToast', 'danger', response.data);
            }
        }).catch(error => {
            eventBus.$emit('makeToast', 'danger', 'Error al crear el usuario.');
        });
    }
}
