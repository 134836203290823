import axios from "axios";
import { GenericResponse } from "./interceptors";

export interface AccountOrder {
    id:         number;
    status:     number;
    recipes:    number;
    price:      number;
    position:   number;
    created_at: number;
    updated_at: number;
    invoice_requested: number;
}
class AccountOrderService {

    getAll():Promise<AccountOrder[]> {
        return axios.get(`/v1/account-order/index`);
    }


    requestInvoice(id:number):Promise<GenericResponse> {
        return axios.get(`/v1/account-order/request-invoice?id=${id}`);
    }
}

export default new AccountOrderService();
