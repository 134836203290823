
import { Component, Watch, Vue } from 'vue-property-decorator';
import RecipeService, { FullData, Recipe } from '@/services/recipes.service';
import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Steps from '@/components/recipe-detail/Steps.vue';
import { GenericResponse } from '@/services/interceptors';

@Component({
  components: {
    Header, 
    Footer, 
    Sidebar,
    Steps,
  },
})
export default class RecipePacking extends Vue {
  
  data():any {
    return {
      model: null,
      ingredients: [],
      fields: [
        { key: 'index', label: '#' },
        { key: 'name', label: 'Nombre' },
        { key: 'quantity', label: 'Cantidad' },
        { key: 'waste_percent', label: 'Desperdicio (%)' },
        { key: 'grams', label: 'Total' },
      ],
      unitOptions: [
        { value: 'gram', text: 'gramos' },
        { value: 'ml', text: 'mililitros' },
      ],
      canContinue: false,
    };
  }

  @Watch('model', { deep: true })
  onModelChange(newVal: Recipe):void {
    for(let i = 0; i < newVal.ingredients.length; i++) {
      RecipeService.updateIngredient(this.$data.model.id, newVal.ingredients[i].id, {
        mx_sugar_added: newVal.ingredients[i].mx_sugar_added,
      }).then((response:FullData) => {
        // this.$data.model = model;
        // this.$data.ingredients = model.ingredients;
        // console.log(response);
      }).catch(error => {
        // console.log(error);
      });
    }

    RecipeService.updateOne(this.$data.model.id, {
        servings: newVal.servings,
        net_weight_per_package_unit: newVal.net_weight_per_package_unit,
        net_weight_per_package: newVal.net_weight_per_package,
        packages: newVal.packages
      }).then((response:FullData) => {
        // this.$data.model = model;
        // this.$data.ingredients = model.ingredients;
        // console.log(response);
      }).catch(error => {
        // console.log(error);
      });

      this.$data.canContinue = (parseFloat(newVal.packages) * parseFloat(newVal.net_weight_per_package)) == parseFloat(newVal.grams);
  }

  created():void {
    this.getModel(Number.parseInt(this.$route.params.id));
  }

  getModel(id:number):void {
    RecipeService.getOne(id).then((model:Recipe) => {
      this.$data.model = model;
      // this.$data.ingredients = model.ingredients;
    }).catch(error => {
      this.$data.model = null;
      this.$router.push('/home');
    //   console.log(error);
    });
  }

  prevStep():void {
    this.$router.push(`/recipe-formula/${this.$data.model.id}`);
  }


  nextStep():void {
    this.$router.push(`/home`);
  }

  isSolid(unit:string):boolean {
      unit = unit.toUpperCase();
      let result = false;
      switch(unit) {
          case 'ML':
          case 'FLUID_OZ':
              result = false;
              break;
          case 'GRAM':
          case 'OZ':
              result = true
              break;
      }

      return result;
  }

  toGrams(qty:number, unit:string):number {

    unit = unit.trim().toUpperCase();
    let grams = 0;
    switch(unit) {
        case 'FLUID_OZ':
            grams = qty * 29.57352956;
            break;
        case 'OZ':
            grams = qty * 28.3495;
            break;
        case 'MG':
            grams = qty / 1000.0;
            break;
        case 'UG':
            grams = qty / 1000000.0;
            break;
        case 'G':
        case 'GRAM':
        case 'ML':
            grams = qty * 1.0;
            break;
    }

    return grams;
  }
}
