
import { Component, Vue } from 'vue-property-decorator';
import AuthService, { Identity, User } from '@/services/auth.service';
import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Info from '@/components/Info.vue';
import { GenericResponse } from '@/services/interceptors';
import eventBus from '@/services/eventBus';
import AddUser from '@/components/my-users/AddUser.vue';
import UpdateUser from '@/components/my-users/UpdateUser.vue';


@Component({
  components: {
    Header, 
    Footer, 
    Sidebar,
    Info,
    AddUser,
    UpdateUser
  },
})
export default class UsersTable extends Vue {
  data() {
    return {
      fields: [
        { key: 'index', label: '#' },
        { key: 'status', label: 'Estatus' },
        { key: 'name', label: 'Nombre' },
        { key: 'email', label: 'Email' },
        { key: 'updated_at', label: 'Última actualización' },
        { key: 'id', label: '' },
        // { key: 'update', label: '' },
      ],
      items: [],
    };
  }

  mounted():void {
    this.getItems();
    eventBus.$on('reloadUsersAccess', () => { this.getItems(); });

  }

  getItems():void {
    AuthService.getUsers().then((items:User[]) => {
      this.$data.items = items;
    }).catch(error => {
      this.$data.items = [];
    });
  }

  updateUser(model:User) {
    eventBus.$emit('realoadUserAccess', model);	
    
  }

  deleteUser(id:number) {
    AuthService.deleteUser(id).then((response:GenericResponse) => {
      if(response.success) {
        this.getItems();
      } else {
        eventBus.$emit('makeToast', 'danger', response.data);
      }
    }).catch(error => {
      eventBus.$emit('makeToast', 'danger', 'Error al eliminar.');
    });
  }
}
