
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Identity } from '@/services/auth.service';
import AuthService from '@/services/auth.service'; // @ is an alias to /src
import { GenericResponse } from '@/services/interceptors';

@Component
export default class Profile extends Vue {
    // @Prop() private identity!: Identity;

    data() {
        return {
            canContinue: true,
            taxTypeOptions: [
                { value: '601', text: '601 General de Ley Personas Morales' },
                { value: '603', text: '603 Personas Morales con Fines no Lucrativos' },
                { value: '605', text: '605 Sueldos y Salarios e Ingresos Asimilados a Salarios' },
                { value: '606', text: '606 Arrendamiento' },
                { value: '607', text: '607 Régimen de Enajenación o Adquisición de Bienes' },
                { value: '608', text: '608 Demás ingresos' },
                { value: '609', text: '609 Consolidación' },
                { value: '610', text: '610 Residentes en el Extranjero sin Establecimiento Permanente en México' },
                { value: '611', text: '611 Ingresos por Dividendos (socios y accionistas)' },
                { value: '612', text: '612 Personas Físicas con Actividades Empresariales y Profesionales' },
                { value: '614', text: '614 Ingresos por intereses' },
                { value: '615', text: '615 Régimen de los ingresos por obtención de premios' },
                { value: '616', text: '616 Sin obligaciones fiscales' },
                { value: '620', text: '620 Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
                { value: '621', text: '621 Incorporación Fiscal' },
                { value: '622', text: '622 Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
                { value: '623', text: '623 Opcional para Grupos de Sociedades' },
                { value: '624', text: '624 Coordinados' },
                { value: '625', text: '625 Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas' },
                { value: '626', text: '626 Régimen Simplificado de Confianza' },
                { value: '628', text: '628 Hidrocarburos' },
                { value: '629', text: '629 De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales' },
                { value: '630', text: '630 Enajenación de acciones en bolsa de valores' },
            ],
            cfdiUsageOptions: [
                { value: 'G01', text: 'G01 Adquisición de mercancías' },
                { value: 'G02', text: 'G02 Devoluciones, descuentos o bonificaciones' },
                { value: 'G03', text: 'G03 Gastos en general' },
                { value: 'I01', text: 'I01 Construcciones' },
                { value: 'I02', text: 'I02 Mobiliario y equipo de oficina por inversiones' },
                { value: 'I03', text: 'I03 Equipo de transporte' },
                { value: 'I04', text: 'I04 Equipo de cómputo y accesorios' },
                { value: 'I05', text: 'I05 Dados, troqueles, moldes, matrices y herramental' },
                { value: 'I06', text: 'I06 Comunicaciones telefónicas' },
                { value: 'I07', text: 'I07 Comunicaciones satelitales' },
                { value: 'I08', text: 'I08 Otra maquinaria y equipo' },
                { value: 'D01', text: 'D01 Honorarios médicos, dentales y gastos hospitalarios.' },
                { value: 'D02', text: 'D02 Gastos médicos por incapacidad o discapacidad' },
                { value: 'D03', text: 'D03 Gastos funerales.' },
                { value: 'D04', text: 'D04 Donativos' },
                { value: 'D05', text: 'D05 Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).' },
                { value: 'D06', text: 'D06 Aportaciones voluntarias al SAR.' },
                { value: 'D07', text: 'D07 Primas por seguros de gastos médicos.' },
                { value: 'D08', text: 'D08 Gastos de transportación escolar obligatoria.' },
                { value: 'D09', text: 'D09 Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.' },
                { value: 'D10', text: 'D10 Pagos por servicios educativos (colegiaturas)' },
                { value: 'P01', text: 'P01 Por definir' },
            ]
        };
    }

    get isAuthenticated() {
        return this.$store.getters.isAuhtenticated;
    }

    get identity() {
        return this.$store.getters.getIdentity;
    }

    save():void {
        AuthService.updateProfile(this.identity.account).then((response:GenericResponse) => {
            if(response.success) {
                this.$store.commit('setIdentity', response.data);
            } else {
                // Alert error
            }
        }).catch(error => {
            console.log(error);
        });
    }

}
