<template>
    <div class="login">
        <Header title="Iniciar sesión"/>
        <div class="row">
            <div class="col-md-6 offset-md-3 my-5">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title text-primary font-weight-bold">Nueva contraseña</h5>
                        <form action class="form" @submit.prevent="submit">
                            <div class="form-group">
                                <label for="exampleInputPassword1">Contraseña</label>
                                <input type="password" v-model="form.password" required class="form-control" id="exampleInputPassword1">
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Confirmar contraseña</label>
                                <input type="password" v-model="form.password_confirm" required class="form-control" id="exampleInputPassword1">
                            </div>
                            <b-alert v-if="error" variant="danger" show class="font-italic">No se ha encontrado una solicitud válida.</b-alert>
                            <div v-if="success == true" class="alert alert-success my-3" role="alert">
                               Se ha actualizado la contraseña. Ahora puedes iniciar sesión.
                            </div>
                            <div class="clearfix">
                                <div class="float-left">
                                    <router-link to="/">Ir a Iniciar sesión</router-link>
                                </div>
                                <button type="submit" class="btn btn-success float-right">Enviar solicitud</button>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
import AuthService from '@/services/auth.service'; // @ is an alias to /src
import Header from '@/components/Header.vue'; // @ is an alias to /src
import { required, sameAs } from 'vuelidate/lib/validators';

export default {
    components: { Header },

    directives: { },

    data() {
        return {
            form: {
                token: null,
                password: null,
                password_confirm: null,
            },
            error: false,
            success: false,
        };
    },

    validations() {
        return {
            form: {
                token: { required },
                password: { required },
                password_confirm: {
                    required,
                    passwordConfirm: sameAs('password')
                },
            }
        };
    },


    mounted() {
        console.log( this.$route.params );
        this.$data.form.token = this.$route.params.token;
        // if(this.$router.currentRoute.path == '/password-reset-sent-success') {
        //     this.$data.success = true;
        // }
    },

    methods: {
        submit() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                AuthService.resetPassword(this.form.token, this.form.password).then(response => {
                    this.form.token = null;
                    this.form.password = null;
                    this.form.password_confirm = null;
                    this.success = true;
                    this.error = false;
                }).catch(error => {
                    this.error = true;
                    this.success = false;
                    console.log(error.response.data);
                });
            }
        }
    },
};
</script>

<style lang="scss" scoped>

</style>