
import { Component, Watch, Vue } from 'vue-property-decorator';

import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Info from '@/components/Info.vue';
import VerticalLabel from '@/components/labels/VerticalLabel.vue';
import LinearLabel from '@/components/labels/LinearLabel.vue';
import RecipeService, { FullData, Recipe } from '@/services/recipes.service';
import { GenericResponse } from '@/services/interceptors';

@Component({
  components: {
    Header, 
    Footer, 
    Info,
    Sidebar,
    VerticalLabel,
    LinearLabel
  },
})
export default class RecipeLabels extends Vue {
  
  data():any {
    return {
      model: null,
      labelType: 1
    };
  }


  @Watch('model', { deep: true })
  onModelChange(newVal: Recipe):void {
    RecipeService.updateOne(newVal.id, {
        extend_other_nutrients_folid_acid: newVal.extend_other_nutrients_folid_acid,
        extend_other_nutrients_calcium: newVal.extend_other_nutrients_calcium,
        extend_other_nutrients_copper: newVal.extend_other_nutrients_copper,
        extend_other_nutrients_phosphorus: newVal.extend_other_nutrients_phosphorus,
        extend_other_nutrients_iron: newVal.extend_other_nutrients_iron,
        extend_other_nutrients_magnesium: newVal.extend_other_nutrients_magnesium,
        extend_other_nutrients_manganese: newVal.extend_other_nutrients_manganese,
        extend_other_nutrients_niacin: newVal.extend_other_nutrients_niacin,
        extend_other_nutrients_potassium: newVal.extend_other_nutrients_potassium,
        extend_other_nutrients_selenium: newVal.extend_other_nutrients_selenium,
        extend_other_nutrients_vitamin_a: newVal.extend_other_nutrients_vitamin_a,
        extend_other_nutrients_thiamine: newVal.extend_other_nutrients_thiamine,
        extend_other_nutrients_riboflavin: newVal.extend_other_nutrients_riboflavin,
        extend_other_nutrients_vitamin_b6: newVal.extend_other_nutrients_vitamin_b6,
        extend_other_nutrients_vitamin_b12: newVal.extend_other_nutrients_vitamin_b12,
        extend_other_nutrients_vitamin_d: newVal.extend_other_nutrients_vitamin_d,
        extend_other_nutrients_vitamin_e: newVal.extend_other_nutrients_vitamin_e,
        extend_other_nutrients_vitamin_c: newVal.extend_other_nutrients_vitamin_c,
        extend_other_nutrients_vitamin_k: newVal.extend_other_nutrients_vitamin_k,
        extend_other_nutrients_zinc: newVal.extend_other_nutrients_zinc,
        fat_added: newVal.fat_added,
        sodium_added: newVal.sodium_added,
        extend_fat_nutrients: newVal.extend_fat_nutrients,
        include_serving:  newVal.include_serving
      }).then((response:FullData) => {
        // this.$data.model = model;
        // this.$data.ingredients = model.ingredients;
        // console.log(response);
      }).catch(error => {
        // console.log(error);
      });
  }

  created():void {
    this.getModel(Number.parseInt(this.$route.params.id));
  }

  getModel(id:number):void {
    RecipeService.getOne(id).then((model:Recipe) => {
        this.$data.model = model;
    }).catch(error => {
      this.$data.model = null;
      this.$router.push('/home');
    //   console.log(error);
    });
  }
}
