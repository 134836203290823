
import { Component, Watch, Vue } from 'vue-property-decorator';
import XLSX from 'xlsx'

import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Info from '@/components/Info.vue';
import RecipeService, { Recipe } from '@/services/recipes.service';

@Component({
  components: {
    Header, 
    Footer, 
    Info,
    Sidebar
  },
})
export default class RecipeNutritionBreakdown extends Vue {
  
  data():any {
    return {
      fields: [
        { key: 'name', label: 'Ingrediente', thClass: 'table-column text-left', tdClass: 'table-column text-left' },
        { key: 'grams', label: 'Gramos', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'usagePercent', label: 'Gramos(%)', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        
        { key: 'energeticContentByRecipe', label: 'kilocalorías', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'proteinByRecipe', label: 'Proteina', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        
        { key: 'fatsByRecipe', label: 'Grasas totales', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'saturedFatsByRecipe', label: 'Grasas sat.', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'transFatsByRecipe', label: 'Grasas trans', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'poliiFatsByRecipe', label: 'Grasas poliinsaturadas', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'monoFatsByRecipe', label: 'Grasas monoinsaturadas', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'cholesterolByRecipe', label: 'Colesterol', thClass: 'table-column text-center', tdClass: 'table-column text-center' },

        { key: 'carbohydrateByRecipe', label: 'Hidratos de carbonos disponibles', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'sugarByRecipe', label: 'Azúcares totales', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'sugarAddedByRecipe', label: 'Azúcares añadidos', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        
        { key: 'dietaryFiberByRecipe', label: 'Fibra dietética', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'sodiumByRecipe', label: 'Sodio', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'starchByRecipe', label: 'Almidón', thClass: 'table-column text-center', tdClass: 'table-column text-center' },

        { key: 'vitaminAByRecipe', label: 'Vitamina A', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'thiamineByRecipe', label: 'Vitamina B1 (Tiamina)', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'riboflavinaByRecipe', label: 'Vitamina B2 (Riboflavina)', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'vitaminB6ByRecipe', label: 'Vitamina B6', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'niacinByRecipe', label: 'Niacina', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'folicAcidByRecipe', label: 'Ácido Fólico', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'vitaminB12ByRecipe', label: 'Vitamina B12', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'vitaminCByRecipe', label: 'Vitamina C', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'vitaminDByRecipe', label: 'Vitamina D', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'vitaminEByRecipe', label: 'Vitamina E', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'vitaminKByRecipe', label: 'Vitamina K', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'pantothenicAcidByRecipe', label: 'Ácido pantoténico', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'calciumByRecipe', label: 'Calcio', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'copperByRecipe', label: 'Cobre', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'chromiumByRecipe', label: 'Cromo', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'fluorByRecipe', label: 'Flúor', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'phosphorusByRecipe', label: 'Fósforo', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'ironByRecipe', label: 'Hierro', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'magnesiumByRecipe', label: 'Magnesio', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'seleniumByRecipe', label: 'Selenio', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'iodineByRecipe', label: 'Yodo', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'zincByRecipe', label: 'Zinc', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'manganeseByRecipe', label: 'Manganeso', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
        { key: 'potassiumByRecipe', label: 'Potasio', thClass: 'table-column text-center', tdClass: 'table-column text-center' },
      ],
      model: null,
      dataToExport: {
        'animals': [
          {"name": "cat", "category": "animal"}
          ,{"name": "dog", "category": "animal"}
          ,{"name": "pig", "category": "animal"}
        ],
      } 
    };
  }

  created():void {
    
    this.getModel(Number.parseInt(this.$route.params.id));
  }

  getModel(id:number):void {
    RecipeService.getOne(id).then((model:Recipe) => {
        this.$data.model = model;
    }).catch(error => {
      this.$data.model = null;
      this.$router.push('/home');
    //   console.log(error);
    });
  }

  onexport () { // On Click Excel download button
      // // export Excel file

      if(this.$refs.dataTable != null) {
        var tbl = document.getElementById('dataTable');
        var wb = XLSX.utils.table_to_book(tbl);

        // export Excel file
        XLSX.writeFile(wb, `${this.$data.model.name}.xlsx`) // name of the file is 'book.xlsx'
      } else {
        alert('Error al descargar. Intente más tarde.');
      }

  }
}
