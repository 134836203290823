
import { Component, Vue } from 'vue-property-decorator';
import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Info from '@/components/Info.vue';
import Step from '@/components/recipe-detail/Step.vue';

import RecipeService, { Recipe } from '@/services/recipes.service';
import PrivacyContent from '@/components/PrivacyContent.vue'; // @ is an alias to /src

@Component({
  components: {
    Header, 
    Footer, 
    Sidebar,
    Info, 
    Step,
    'privacy-content': PrivacyContent
  },
})
export default class Privacy extends Vue {
  data() {
    return {
      fields: [
        { key: 'index', label: '#' },
        { key: 'name', label: 'Nombre' },
        { key: 'updated_at', label: 'Última actualización' },
        { key: 'id', label: '' },
        // { key: 'update', label: '' },
      ],
      items: [],
    };
  }

  mounted():void {
    this.getItems();
  }

  get isAuthenticated() {
    return this.$store.getters.isAuhtenticated;
  }

  get identity() {
    return this.$store.getters.getIdentity;
  }


  showBuyRecipeAlert():boolean {
    if(this.isAuthenticated) {
      return ( this.identity.account.recipe_limit - this.identity.account.recipe_count ) <= 5;
    }

    return false;
  }

  getItems():void {
    RecipeService.getAll().then((items:Recipe[]) => {
      this.$data.items = items;
    }).catch(error => {
      this.$data.items = [];
    });
  }

  updateRecipe(id:number):void {
    this.$router.push(`/recipe/${id}`);
  }

  updateRecipeCost(id:number):void {
    this.$router.push(`/recipe-cost/${id}`);
  }

  recipeLabels(id:number):void {
    this.$router.push(`/recipe-labels/${id}`);
  }

  recipeNutritionBreakdown(id:number):void {
    this.$router.push(`/recipe-nutrition-breakdown/${id}`);
  }
}
