
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import AuthService, { Identity, User } from '@/services/auth.service';
import eventBus from '@/services/eventBus';
import { GenericResponse } from '@/services/interceptors';

@Component({
  components: {
  },
})
export default class UpdateUser extends Vue {
    @Prop() private model!: User;
    data() {
        return {
            form: {
                status: 10,
                name: null,
                email: null,
            },
            options: [
                {
                    'value': 10,
                    'text': 'Activo',
                },
                {
                    'value': 9,
                    'text': 'Suspendido',
                }
            ]
        };
    }


    mounted() {
        if(this.model) {
            this.$data.form.status = this.model.status;
            this.$data.form.name = this.model.name;
            this.$data.form.email = this.model.email;
        }
    }


    @Watch('model', { deep: true })
    onModelUpdate(newVal:User):void {
        console.log({newVal});
        if(newVal) {
            this.$data.form.status = newVal.status;
            this.$data.form.name = newVal.name;
            this.$data.form.email = newVal.email;
        }
    }

    handleClick() {
        this.$bvModal.show(`modal-update-access-${this.model.id}`);
    }

    handleOk() {
        AuthService.updateUser(this.model.id, { status: this.$data.form.status }).then((response:GenericResponse) => {
            if(response.success) {
                eventBus.$emit('reloadUsersAccess');
                // this.reset();
            } else {
                eventBus.$emit('makeToast', 'danger', response.data);
            }
        }).catch(error => {
            eventBus.$emit('makeToast', 'danger', 'Error al crear el usuario.');
        });
    }
}
