import axios from "axios";
import Cookies from "js-cookie";
import { GenericResponse } from '@/services/interceptors'
import eventBus from '@/services/eventBus';

export interface IdentityResponse {
    success: boolean;
    data: Identity,
}

export interface Identity {
    id:           number;
    access_token: string;
    name:         string;
    email:        string;
    role:         string;
    account:      Account;
}

export interface Account {
    id              : string;
    contact_name    : string;
    contact_phone   : string;
    contact_email   : string;
    recipe_limit    : number;
    recipe_count    : number;
    tax_name        : string;
    tax_id          : string;
    tax_type        : string;
    cfdi_usage      : string;
    allow_to_request_bill      : boolean;
    has_purchases      : boolean;
}

export interface User {
    id:    number;
    status:    number;
    name:  string;
    email: string;
    role:  string;
}

class AuthService {

    setIdentity(identity:Identity|null) {
        Cookies.set('identity', JSON.stringify(identity));
    }

    getIdentity():Identity|null {
        const data = Cookies.get('identity');
        if(data != undefined && data != 'undefined') {
            const identity = JSON.parse(data) as Identity;
            //this.setIdentity(identity);
            return identity;
        }

        return null;
    }

    login(username:string, password:string):Promise<IdentityResponse> {
        const data = { username, password };
        return axios.post('/v1/login', data);
    }

    requestPasswordReset(email:string):Promise<Identity> {
        const data = { email };
        return axios.post('/v1/request-password-reset', data);
    }

    resetPassword(token:string, password:string):Promise<Identity> {
        return axios.post(`/v1/reset-password`, {
            token,
            password
        });
    }


    signup(data:any):Promise<GenericResponse> {
        return axios.post('/v1/signup', data);
    }

    isLoggedIn():Promise<GenericResponse> {
        const identity = this.getIdentity();
        if(identity != null) {
            //return axios.get('/v1/is-logged-in');
            return Promise.resolve({
                success: true,
                data: identity
            } as GenericResponse);
        }

        return Promise.resolve({ success: false } as GenericResponse);
    }


    updateIdentity():Promise<Identity> {
        return axios.get('/v1/profile');
    }

    updateProfile(data:Account):Promise<GenericResponse> {
        return axios.put('/v1/update-profile', data);
    }

    // --

    getUsers():Promise<User[]> {
        return axios.get('/v1/user');
    }

    deleteUser(id:number):Promise<GenericResponse> {
        return axios.delete(`/v1/user/delete-access?id=${id}`);
    }

    addUser(data:any):Promise<GenericResponse> {
        return axios.post(`/v1/user/create-access`, data);
    }

    updateUser(id:number, data:any):Promise<GenericResponse> {
        return axios.put(`/v1/user/update-access?id=${id}`, data);
    }


}

export default new AuthService();
