
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import RecipeService, { FullData, Ingredient, Recipe } from '@/services/recipes.service';
import { Validate, Validations } from 'vuelidate-property-decorators';
import { required, minValue } from 'vuelidate/lib/validators';
import eventBus from '@/services/eventBus';
@Component
export default class WastePercent extends Vue {
  @Prop() private model!: Ingredient;

  data() {
    return {
    }
  }

  @Validations()
  validations() {
      return {
          model: {
            waste_percent: { required, minValue: minValue(0) }
          }
      };
  }

  updateValue() {
    this!['$v'].$touch()
    if (!this!['$v'].$invalid) {

      const value = this.model.waste_percent;
      RecipeService.updateIngredient(this.model.recipe_id, this.model.id, {
          waste_percent: value,
        }).then((data:FullData) => {
              this.$store.commit('updateRecipe', data.label);
              this.$store.commit('updateTable', data.table);
        }).catch(error => {
          eventBus.$emit('makeToast', 'danger', 'Error al actualizar receta', 'Error desconocido');
        });
    } else {
      eventBus.$emit('makeToast', 'danger', 'Cantidad inválida', 'Debe ingresar una cantidad mayor o igual a cero.');
    }
  }
}
