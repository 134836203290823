import axios from "axios";
import { GenericResponse } from "./interceptors";

export interface RecipeCost {
    id:                         number;
    recipe_id:                  number;
    unit_packaging_cost:        number;
    batch_labor_cost:           number;
    batch_overhead_cost:        number;
    your_margin_percent:        number;
    distributor_margin_percent: number;
    broker_margin_percent:      number;
    retailer_margin_percent:    number;
    distributors_price:         number;
    retailers_price:            number;
    consumers_price:            number;
    name:                       string;
    net_weight_per_package_unit:number;
    net_weight_per_package:     number;
    packages:                   number;
    ingredients:                IngredientCost[];
}

export interface IngredientCost {
    id:                   number;
    recipe_id:            number;
    recipe_ingredient_id: number;
    price:                number;
    price_quantity_for:   number;
    price_unit:           number;
    shipping:             number;
    recipe_cost_id:       number;
    name:                 string;
    grams:       number;
    waste_percent:  number;
}

class RecipeCostService {

    getOne(id:number):Promise<RecipeCost> {
        return axios.get(`/v1/recipe-cost/view?id=${id}`);
    }

    updateIngredient(recipeId:number, id:number, data:any):Promise<GenericResponse> {
        return axios.put(`/v1/recipe-cost/update-ingredient?id=${recipeId}&iid=${id}`, data);
    }

    updateOne(id:number, data:any):Promise<GenericResponse> {
        return axios.put(`/v1/recipe-cost/update-recipe?id=${id}`, data);
    }
}

export default new RecipeCostService();
