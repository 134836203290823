import { render, staticRenderFns } from "./ExtendOtherNutrientsPotassium.vue?vue&type=template&id=e88e2964&scoped=true"
import script from "./ExtendOtherNutrientsPotassium.vue?vue&type=script&lang=ts"
export * from "./ExtendOtherNutrientsPotassium.vue?vue&type=script&lang=ts"
import style0 from "./ExtendOtherNutrientsPotassium.vue?vue&type=style&index=0&id=e88e2964&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e88e2964",
  null
  
)

export default component.exports