import { render, staticRenderFns } from "./RecipeIngredients.vue?vue&type=template&id=72ab07af&scoped=true"
import script from "./RecipeIngredients.vue?vue&type=script&lang=ts"
export * from "./RecipeIngredients.vue?vue&type=script&lang=ts"
import style0 from "./RecipeIngredients.vue?vue&type=style&index=0&id=72ab07af&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72ab07af",
  null
  
)

export default component.exports